angular.module('workplacesCreateController', []).controller('workplacesCreateController', function($scope, $window, Workplace, Client, Entrust) {
    
	/**
	 * Initial data
	 */
	
    $scope.loadingGlobal = false;
	$scope.sameAddressAClient = true;
    $scope.workplace = {};

    /**
	 * Get asynchronous data
	 */

    var regex = /client_id=(\d+)/i;
    var search = $window.location.search;
    var result = search.match(regex);
    var blacklist = ['created_at', 'deleted_at', 'updated_at', 'id'];
    
    if (result != null) {
        Client.get(result[1]).success(function (data) { 
            _.each(_.omit(data.address, blacklist), function(value, key) {
                $scope.workplace[key] = value; // populate workplace address with client address
            });
        });
    }

	/**
	 * Model actions
	 */
	
    $scope.create = function() {
        $scope.loadingGlobal = true;
        Workplace.create($scope.workplace)
            .success(function(data) {
                $scope.errors = undefined;
                $window.location = Entrust.can('create_equipment_tree') ? './equipment/create' : './clients';
            })
            .error(function(data) {
                $scope.errors = data;
                $('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
            })
			.finally(function() { $scope.loadingGlobal = false; });
    };
});