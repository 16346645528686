angular.module('formsCreateController', []).controller('formsCreateController', function($scope, $builder, $validator, $q, Document, Form, Operation, Alert, DirtyModel, Route, Draft) {

	$scope.input = [];
    $scope.defaultValue = {};
    
    Form.loadComponents();
    
    /**
     * Check for controller action and id
     */

    var action = Route.getAction()[0];
    var currentId = Route.getAction()[1];

    /**
     * Drafts
     */

    $scope.recoverDraft = function() {
        Draft.recover($scope);
        $scope.rebuildForm();
    };

    Draft.get('form', currentId)
        .success(function(data) {
            $scope.draft = _.isEmpty(data) ? null : data; 
            $scope.displayDraftPanel = !!$scope.draft;
        })
        .error(function(data) { console.log(data); });

    /**
     * Initial data according to action
     */
    
    switch (action) {
        case 'EDIT':
            Form.get(currentId)
                .success(function (data) {
                    $scope.form = data;
                    $scope.rebuildForm();
                    DirtyModel.watch($scope, 'form');
                });
            break;
        case 'CREATE':  
            $scope.form = {
                title: '',
                fields: $builder.forms['default']
            };
            DirtyModel.watch($scope, 'form');
            break;
    }

    /**
     * CRUD operations
     */

    $scope.save = function() {
    	
        $scope.loadingGlobal = true;
        $scope.errors = null;

        Form.create($scope.form)
            .success(function (data) {
                $scope.loadingGlobal = false;
                DirtyModel.reset($scope, 'form');
                Route.setNewRouteId($scope.form, data);
                $('.alert.alert-success').fadeIn().delay(2000).fadeOut();
            })
            .error(function (data) {
                $scope.loadingGlobal = false;
                $scope.errors = data;
                $('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
            });
    };

    /**
     * Rebuild the form to stick with angular-formbuilder module
     */

    $scope.rebuildForm = function() {
        $builder.forms['default'].length = 0;
        $scope.form.fields.forEach(function (field) {
            $builder.addFormObject('default', field);
        });
        $scope.form.fields = $builder.forms['default'];
    };
});
