angular.module('clientsCreateController', []).controller('clientsCreateController', function($scope, $window, Client, Entrust) {
    
	/**
	 * Initial data
	 */
	
    $scope.loadingGlobal = false;
    
	/**
	 * Model actions
	 */
	
    $scope.create = function() {
        $scope.loadingGlobal = true;
        Client.create($scope.client)
            .success(function(data) {
                $scope.errors = undefined;
                $window.location = Entrust.can('create_workplace') ? ('./workplaces/create?client_id=' + data.id) : './clients';
            })
            .error(function(data, code) {
                if (code === 500) {
                    $('.alert.alert-error.license').fadeIn();
                }
                else {
                    $scope.errors = data;
                    $('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
                }
            })
			.finally(function() { $scope.loadingGlobal = false; });
    };
});