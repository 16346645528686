angular.module('ticketService', []).factory('Ticket', function($http) {

    /**
	 * Construction chaine de caractère pour filtres
	 * @param filters 
	 */
  	var formatFilters = function(filters, filtersPeriod) {
        var string = ''
        
        if (filters) {
            if (filters.designation) { string += '&number=' + filters.designation }
            if (filters.title) { string += '&title=' + filters.title }
            if (filters.state) { string += '&state=' + filters.state }
            if (filters.priority) { string += '&priority=' + filters.priority }
            if (filters.client.designation) { string += '&client=' + filters.client.designation }
            if (filters.workplace.designation) { string += '&workplace=' + filters.workplace.designation }
            if (filters.user.login) { string += '&user=' + filters.user.login }
            if (filters.responsable.login) { string += '&responsable=' + filters.responsable.login }
            if (filters.archived) { string += '&archived=' + filters.archived }
        }


        if (filtersPeriod) {
            if (filtersPeriod.created_at.length != 0) {
                string += '&start_at=' + moment(filtersPeriod.created_at, 'dddd LL').format('YYYY/MM/DD')
            }
            if (filtersPeriod.finished_at.length != 0) {
                string += '&end_at=' + moment(filtersPeriod.finished_at, 'dddd LL').format('YYYY/MM/DD')
            }
        }

		return string;
    }
    
    return {

        get: function(id) {
            return $http({
                method: 'GET',
                url: 'tickets' + (id != null ? '/' + id : ''),
                offline: true
            });
        },
        paginate: function(page, filters, filtersPeriod) {
            var url = 'tickets?page=' + page
            url += formatFilters(filters, filtersPeriod)

            return $http({
                method: 'GET',
                url: url,
                offline: true
            });
        },
        getLast: function(limit) {
            return $http({
                method: 'GET',
                url: 'api/tickets/get_last/' + limit,
                offline: true
            });
        },
        getResponsable: function() {
            return $http({
                method: 'GET',
                url: 'api/tickets/get_responsable',
                offline: true
            });
        },
        create: function(data) {
            return $http({
                method: 'POST',
                url: 'tickets',
                offline: true,
                data: data
            });
        },
        update: function(id, data) {
            return $http({
                method: 'POST',
                url: 'tickets/' + id,
                offline: true,
                data: _.assign({ _method: 'PATCH' }, data)
            });
        },
        destroy: function(id) {
            return $http({
                method: 'POST',
                url: 'tickets/' + id,
                offline: true,
                data: {_method: 'DELETE'}
            });
        },
        timeline: function(id) {
            return $http({
                method: 'GET',
                url: 'tickets/' + id + '/timeline',
                offline: true
            });
        },
        counts: function(filters, filtersPeriod) {
            var url = 'api/tickets/stats/count?filters'
            url += formatFilters(filters, filtersPeriod)

            return $http({
                method: 'GET',
                url: url,
                offline: true
            });    
        },
        stats: function() {
            return $http({
                method: 'GET',
                url: 'api/tickets/stats/graph',
                offline: true
            });
        },
        notify: function(data) {
            return $http({
                method: 'POST',
                url: 'tickets/' + data.ticket_id + '/notify',
                offline: true,
                data: data
            });
        },
        export: function(format, filters, filtersPeriod) {
            var url = 'tickets.export?&format=' + format
            url += formatFilters(filters, filtersPeriod)

            return $http({
                method: 'GET',
                url: url,
                offline: true,
                responseType: 'arraybuffer'
            });
        },
    };
});
