angular.module('clientsIndexController', []).controller('clientsIndexController', function($scope, $timeout, $location, $window, Client, Workplace, Alert, Loading, Entrust, Contact, MaintenanceType, Equipment, uuid4) {

	/**
	 * Initial data
	 */
    $scope.selectedCaracter = '';
    $scope.activity = null;
    $scope.equipment_type_tree = null;

    $scope.maps = []; // google map references
    $scope.clients = [];
    $scope.withTrashedClients = false;
    $scope.withTrashedWorkplaces = false;

    $scope.$emit('tab', 'workplaces');

    /**
	 * Get asynchronous data
     */

    var selectedId = $location.path().split('/')[1]; // auto-selection of resource

	Loading.register('clients', Client.getDesignations(), function (data) { 
        $scope.clients = data; 
        if (selectedId != null) {
            $scope.select(parseInt(selectedId));
        }
        $scope.groupedClients = _.groupBy($scope.clients, function(client) {return client.designation[0].toUpperCase(); });
    });

	Loading.register('activities', MaintenanceType.get(), function (data) { 
        $scope.activities = data;
    });

	/**
	 * Utils
	 */
    $scope.changeCaracterBy = function (caracter) {
        $scope.selectedCaracter = caracter;
    };
    
    var filterTrashed = function(collection) {
        return _.filter(collection, {isTrashed: false});
    }; 
	
	$scope.countBy = function(collection, state) {
		return (state != null) ? _.filter(collection, {state: state, isTrashed: false}).length : filterTrashed(collection).length;
	};
    
    /**
     * Selection
     */

    $scope.select = function(id) {
        if (!Entrust.can('display_client')) return;
        Loading.register('clients', Client.get(id), function (data) { 
            $scope.client = data;
        });
        $scope.contact = {};
        Contact.get(id)
            .success(function (data){
                $scope.contacts = data;
            })
            .error(function (data) { console.log(data); });

        $location.path(id);
    };

    $scope.isSelected = function(id) {
        return $scope.client != null && $scope.client.id === id;
    };

    $scope.selectWorkplace = function(workplace) {
        $scope.selectedWorkplace = workplace;
        
        /* 
         * Google map modal fix
         * We need this to resize the map to get it correctly displayed in the modal
         */

       $('#showWorkplace').one('shown.bs.modal', function() {
            $scope.maps.forEach($scope.fixGoogleMap);
        });
    };

    /**
     * Data selection
     */

    $scope.getClientsData = function() {
        return ($scope.withTrashedClients) ? $scope.clients : filterTrashed($scope.clients);
    };

    $scope.getWorkplacesData = function() {
        return ($scope.withTrashedWorkplaces) ? $scope.client.workplaces : filterTrashed($scope.client.workplaces);
    };

    /**
     * Google map
     */

    $scope.$on('mapInitialized', function (evt, map) {
        $scope.maps.push(map);
        $scope.fixGoogleMap(map);
    });

    $scope.fixGoogleMap = function(map) {
        google.maps.event.trigger(map, 'resize');
        map.setCenter(map.markers[0].position);
    };

    $scope.openGoogleMap = function(workplace) {
        $window.open("http://maps.google.com/?q=" + [workplace.address.street, workplace.address.city, workplace.address.state].join(' '), '_blank');
    };

    /**
	 * Model actions
     */

    var reGenerateNestedIds = function(items) {
        // re-generate all nested _id to prevent collision when you import 2 or more times the same tree
        items.forEach(function (item) {
            item._id = uuid4.generate();
            if (item.items.length) {
                return reGenerateNestedIds(item.items);
            }
        });
        return items;
    };

    $scope.importActivity = function() {
        var items = reGenerateNestedIds(angular.copy($scope.equipment_type_tree.items));
        $scope.client.workplaces
            .filter(function (w) { return w.to_be_updated == '1' })
            .forEach(function(w) {
                // re-generate all nested _id to prevent collision when you import 2 or more times the same tree
                w.equipment_tree.items = items;
                Equipment.create(w.equipment_tree).success(function(data) {
                    // update equipment_tree _id in current view
                    w.equipment_tree._id = data._id;
                })
            });
        $('.alert.alert-success').fadeIn().delay(2000).fadeOut();
        $('#importActivities').modal('hide');
        $scope.equipment_type_tree = null;
        $scope.activity = null;
    };

    $scope.destroy = function () {
        Client.destroy($scope.client.id)
            .success(function (data) { 
                $scope.client.isTrashed = !$scope.client.isTrashed; 
                $scope.client.workplaces.forEach(function (workplace) {
                    workplace.isTrashed = !workplace.isTrashed;
                    if (workplace.equipment_tree != null) {
                        workplace.equipment_tree.isTrashed = !workplace.equipment_tree.isTrashed;
                    }
                });
            })
            .error(function (data) { console.log(data); });
    };

    $scope.destroyWorkplace = function (workplace) {
        Workplace.destroy(workplace.id)
            .success(function (data) { 
                workplace.isTrashed = !workplace.isTrashed; 
                if (workplace.equipment_tree != null) {
                    workplace.equipment_tree.isTrashed = !workplace.equipment_tree.isTrashed;
                }
            })
            .error(function (data) { console.log(data); });
    };

    $scope.destroyContact = function(id) {
        Alert.confirm(function() { return Contact.destroy(id) }, function() {
            _.remove($scope.contacts, function (contact) { return contact.id === id; });
        });
    };
});