angular.module('maintenanceTypeService', []).factory('MaintenanceType', function($http) {

    return {

        get: function() {
            return $http({
                method: 'GET',
                url: 'maintenance-types',
                offline: true
            });
        },
        create: function(data) {
            return $http({
                method: 'POST',
                url: 'maintenance-types',
                offline: true,
                data: data
            });
        },
      	update: function(id, data) {
            return $http({
                method: 'POST',
                url: 'maintenance-types/' + id,
                offline: true,
                data: _.assign({ _method: 'PATCH' }, data)
            });
        },
      	destroy: function(id) {
            return $http({
                method: 'POST',
                url: 'maintenance-types/' + id,
                offline: true,
               	data: {_method: 'DELETE'}
            });
        }
    };
});
