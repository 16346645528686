angular.module('entrustService', []).factory('Entrust', function($http, Upload) {
    
    var permissions = {};
    var roles = [];
    var client_id = null;
    
    return {
        
        init: function() {
            return $http({ cache: true, method: 'GET', url: 'users/permissions' })
                .success(function(data) { 
                    permissions = data.permissions;
                    roles = data.roles;
                    client_id = data.client_id;
                })
                .error(function(data) { console.log(data) });
        },
        can: function(permission, requireAll) {
            requireAll = requireAll || false;
            if (Array.isArray(permission)) {
                for (var i = 0; i < permission.length; i++) {
                    var key = permission[i];
                    var hasPerm = permissions[key];
                    if (hasPerm && !requireAll) {
                        return true;
                    }
                    else if (!hasPerm && requireAll) {
                        return false;
                    }
                }
                return requireAll;
            }
            return permissions[permission] || false;
        },
        hasRole: function(role) {
            return _.includes(roles, role);
        },
        isClient: function() {
            return client_id;
        }
    };
});
