angular.module('trelloController', []).controller('trelloController', function($timeout, $scope, $window, moment) {
    
    $scope.sended = false;
    
    var success = function(successMsg) {
        
        $scope.description = null;
        $scope.sended = true;
        
        $timeout(function() { $('#reportIssue').modal('hide') }, 3000);
        $timeout(function() { $scope.sended = false }, 4000);
    };

    var error = function(errorMsg) {
        $('#reportIssue .alert.alert-error').fadeIn();
    };
    
    $scope.submit = function() {
        
        var description = [
            $scope.description + '\n',
            '**Adresse :** ' + $window.location.href,
            '**Compte utilisé :** ' + $('html').attr('user'),
            '**Date :** ' + moment().format('LLLL'),
            '**Navigateur :** ' + navigator.userAgent
        ].join('\n');
        
        var card = {
            name:   "Bug rapporté par " + $window.location.hostname.split('.')[0], 
            desc:   description,
            pos:    "top", 
            due:    null,
            idMembers: '5613c6a481b13fa6f7f1cf53,5613a1b8a0630eed26dcf4c4,561397d27caef909f71afb22,57c7573af0fe3aac9d00dd1a',
            idList: "57f366f74bd76a797304683f",
            token:  "d1feefd3b106d935f8f6eff279fd3e1f554eb86b34d06e9c92b2e1a870107a58"
        };
        
        Trello.post('/cards', card, success, error);
    };
});