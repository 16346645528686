angular.module('operationService', []).factory('Operation', function($http) {

    return {

        get: function(reportId) {
            return $http({
                method: 'GET',
                url: 'operations/' + reportId,
                offline: true,
            });
        },
        create: function(data) {
            return $http({
                method: 'POST',
                url: 'operations',
                offline: true,
                data: data
            });
        },
       	update: function(id, data) {
            return $http({
                method: 'POST',
                url: 'operations/' + id,
                offline: true,
                data: _.assign({ _method: 'PATCH' }, data)
            });
        }
    };
});
