angular.module('remarksIndexController', []).controller('remarksIndexController', function($scope, Remark, Client, Workplace, Alert, Filter, Loading) {
    
	/**
	 * Initial data
	 */
	
	$scope.remarks = [];
	$scope.search = {
		designation: '',
		state: '',
		priority: '',
	};
	$scope.searchPeriod = {
		created_at: '',
		finished_at: ''
	};
	
	/**
	 * Filters
	 */
	
	Filter.enable($scope, 'search');
	Filter.enable($scope, 'searchPeriod', true);
	
	/**
	 * Utils
	 */
	
    var filterTrashed = function(collection) {
        return _.filter(collection, {isTrashed: false});
    }; 

	$scope.countBy = function(collection, state) {
		return (state != null) ? _.filter(collection, {state: state}).length : collection.length;
	};
	
	$scope.timestampToDate = function(timestamp) {
		return new Date(timestamp);	
	};
	
	$scope.getWorkplaces = function() {
		return _.isEmpty($scope.search.client.designation) ? $scope.workplaces : _.find($scope.clients, {designation: $scope.search.client.designation}).workplaces;
	};

	$scope.show = function (id) {
		$('#showRemark' + id).modal('show');
	};
	
	/**
	 * PAGINATION
	 */
	$scope.pagination = {}

	var formatPagination = function(queryResult) {
		if (queryResult) {
			$scope.pagination = {
				current: queryResult.current_page,
				last: queryResult.last_page == queryResult.current_page ? null : queryResult.last_page,
				previous: queryResult.current_page == 1 ? null : (queryResult.current_page - 1),
				next: queryResult.last_page == queryResult.current_page  ? null : (queryResult.current_page + 1)
		  	}
	
		  	$scope.pagination.range = formatPaginationRange()
		} else {
		  	$scope.pagination = {
				current: null,
				last: null,
				previous: null,
				next: null,
				range: []
		  	}
		}    
	}

	/**
	 * Construct range [1,2,3]
	 * @param pagination 
	 */
  	var formatPaginationRange = function() {
		var range = [];
		let limit = $scope.pagination.last ? $scope.pagination.last : $scope.pagination.current
		for(var i = -2 ; i < 3; i ++) {
			var pageNumber = $scope.pagination.current + i
			if (pageNumber > 0 && pageNumber <= limit) {
				range.push(pageNumber)
			}
		}
		return range;
	}

	/**
	 * Changement de page
	 */
	$scope.goToPage = function(page) {
		$scope.getRemarks(page)
	}

	/**
	 * Get asynchronous data
	 */
	// Récupération des rapports
	$scope.getRemarks = function(page) {
		Loading.register('remarks', Remark.paginate(page, $scope.search, $scope.searchPeriod), function (response) {
			$scope.remarks = response.data;
			formatPagination(response);
		});

		Remark.counts($scope.search, $scope.searchPeriod).success(function(data) { $scope.stats = data });
	}

	$scope.getRemarks(1);

	// Réinitialisation des filtres
	$scope.resetFilters = function() {
		$scope.filter.reset($scope.search); 
		$scope.filter.reset($scope.searchPeriod);

		setTimeout(function(){
			$scope.getRemarks(1);
		}, 1000);
	}
    
	/**
	 * Model actions
	 */
	
    $scope.destroy = function(id) {
        Alert.confirm(
            function() { return Remark.destroy(id) }, 
            function() { _.remove($scope.remarks, function (model) { return model._id === id; })}
        );
    };
});