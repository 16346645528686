angular.module('routeService', []).factory('Route', function($timeout) {

    return {

        getAction: function() {
            
            var parts = _.compact(location.pathname.split('/').reverse());
            var action, id = null;

            switch (parts[0]) {
                case 'edit':
                    action = 'EDIT';
                    id = parts[1];
                    break;
                case 'create':
                    action = 'CREATE';
                    break;
                default:
                    if (!isNaN(parts[0])) {
                        action = 'SHOW';
                        id = parts[0];
                    }
                    else {
                        action = 'INDEX';
                    }
            }

            return [action, id];
        },

        setNewRouteId: function(oldModel, newModel) {
            $timeout(function() {
                // from an edit route
                if (oldModel._id != null) {
                    location.pathname = location.pathname.replace(oldModel._id, newModel._id); 
                }
                // from a create route
                else {
                    var to = location.pathname.lastIndexOf('/');
                    location.pathname = [location.pathname.substring(0, to), newModel._id, 'edit'].join('/');
                }
            });
        }
    };
});
