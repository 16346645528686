angular.module('alertService', []).factory('Alert', function($translate, SweetAlert) {

    return {

        confirm: function(confirmPromise, successCallback) {
            return SweetAlert.swal({   
                title:              $translate.instant('sweetalert.title'),
                text:               $translate.instant('sweetalert.text'),
                type:               "warning",   
                showCancelButton:   true,   
                cancelButtonText:   $translate.instant('sweetalert.cancelButtonText'),
                confirmButtonColor: "#DD6B55",   
                confirmButtonText:  $translate.instant('sweetalert.confirmButtonText'),
                closeOnConfirm:     false 
            }, function (isConfirm) {
                if (isConfirm) {
                    confirmPromise()
                        .success(function(data) {
                            successCallback();
                            SweetAlert.swal($translate.instant('sweetalert.success.title'), $translate.instant('sweetalert.success.text'), "success");   
                        })
                        .error(function(data) { console.log(data); });
                } 
                else {
                    SweetAlert.swal($translate.instant('sweetalert.error.title'), $translate.instant('sweetalert.error.text'), "error");   
                }
            });
        },

        reopen: function(confirmPromise, successCallback) {
            return SweetAlert.swal({
                    title: $translate.instant('sweetalert.title'),
                    text: $translate.instant('sweetalert.reopen.text'),
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#8CD4F5",
                    confirmButtonText: $translate.instant('sweetalert.reopen.confirmButtonText'),
                    cancelButtonText: $translate.instant('sweetalert.cancelButtonText'),
                    closeOnConfirm: false,
                    closeOnCancel: true
                },
                function(isConfirm){
                    if (isConfirm) {
                        confirmPromise()
                            .success(function(data) {
                                successCallback();
                                SweetAlert.swal($translate.instant('sweetalert.reopen.success.title'), $translate.instant('sweetalert.reopen.success.text'), "success");
                            })
                            .error(function(data) { console.log(data); });
                    }
                });
        }
    };
});
