angular.module('reportsShowController', []).controller('reportsShowController', function($scope, $timeout, $filter, $location, Report, Operation, Remark, Route, Alert, Form) {
    
	/**
	 * Initial data
	 */
    $scope.ITEMS_PER_PAGE = 10;
    $scope.nbReports = 0;
    $scope.operations = [];
    $scope.remarks = [];
    $scope.tickets = [];
    $scope.viewMode = true;
    $scope.filter = $filter('uiTreeFilter');
    $scope.options = {
        accept: function(sourceNodeScope, destNodesScope, destIndex) {
            return true;
        }
    };
    
    var currentId = Route.getAction()[1];
    var currentNode;

    Form.loadComponents();
    
	/**
	 * Utils
	 */

    $scope.constructChartData = _.memoize(function (data, reference){
        var array = [];
        array.push(data);

        if (reference != null) {
            var horizontalLines = [];
            for (var i = 0; i < data.length; i++) {
                horizontalLines.push(reference);
            }
            array.push(horizontalLines)
        }
        return array;
    });
    
    $scope.isArray = angular.isArray;
	
    $scope.getOperationByEquipment = function(item) {
        return (item == null) ? null : _.find($scope.operations, {equipment_id: item._id});
    };
    
    $scope.toggleViewMode = function() {
        $scope.viewMode = !$scope.viewMode;
    };
    
    $scope.timestampToDate = function(timestamp) {
		return new Date(timestamp);	
	}; 
    
    $scope.getNbOperators = function() {
        return _.uniq($scope.operations, function(x) { return x.user_id; }).length
    }; 
    
    $scope.getNbReports = function() {
        return ($scope.report != null) ? $scope.report.operations.length : 0;
    };
    
	/**
	 * Get asynchronous data
	 */
	
	$scope.loadingGlobal = true;
    Report.get(currentId)
        .success(function (data) { 
            $scope.report = data;
            $scope.list = data.equipment_tree; 
            $scope.nbReports = data.operations.length;
        })
        .finally(function() { $scope.loadingGlobal = false; });
    Operation
        .get(currentId).success(function (data) { $scope.operations = data; })
        .finally(function() { $scope.loadingGlobal = false; });

    $scope.getReportRemarks = function () {
        Remark.get(currentId)
            .success(function (data) { $scope.remarks = data; })
            .finally(function() { $scope.loadingGlobal = false; });
    };

    $scope.getReportTickets = function (){
        Report.tickets(currentId)
            .success(function (data) { $scope.tickets = data; })
            .finally(function() { $scope.loadingGlobal = false; });
    };
        
    /**
     * Node selection TODO REFACTOR
     */ 

    $scope.checkIfActive = function(scope) {
        $timeout(function() {
            if ($location.path().split('/')[1] === scope.$modelValue._id) {
                $scope.selectItem(scope);
            }
        }, 200);
    };

    $scope.isNodeSelected = function(node) {
        return node === currentNode;
    };

    $scope.hasOperation = function(node) {
        return _.find($scope.operations, {equipment_id: node.$modelValue._id}) != null;
    };

    $scope.hasRemark = function(node) {
        return _.find($scope.remarks, {equipment_id: node.$modelValue._id}) != null;
    };

    $scope.isSelectable = function(node) {
        return node.$modelValue.form_id != null;
    };

    $scope.selectItem = function(scope) {
        if ($scope.isSelectable(scope) && $scope.hasOperation(scope)) {
            currentNode = scope;
            $scope.item = scope.$modelValue;
            $location.path($scope.item._id);
        }
    };

    $scope.unselectItem = function() {
        currentNode = null;
        $scope.item = null;
        $location.path(null);
    };
        
	/**
	 * Model actions
	 */
     $scope.update = function () {
        $scope.loadingGlobal = true;

        Report.update($scope.report.id, $scope.report)
			.success(function(report) {
				$('.alert.alert-success').fadeIn().delay(2000).fadeOut();
			})
			.error(function(data) {
				console.log(data);
				$('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
			})
			.finally(function() { $scope.loadingGlobal = false; });
     };
	
    $scope.destroy = function(id) {
        Alert.confirm(
            function() { return Report.destroy(id) }, 
            function() { $scope.report = undefined }
        );
    };
});