angular.module('equipmentService', []).factory('Equipment', function($http) {

    return {

        get: function(treeId) {
            return $http({
                method: 'GET',
                url: 'equipment/' + treeId,
                offline: true
            });
        },
        create: function(data) {
            return $http({
                method: 'POST',
                url: 'equipment',
                offline: true,
                data: data
            });
        },
      	update: function(treeId, data) {
            return $http({
                method: 'POST',
                url: 'equipment/' + treeId,
                offline: true,
                data: _.assign({ _method: 'PATCH' }, data)
            });
        },
      	destroy: function(treeId) {
            return $http({
                method: 'POST',
                url: 'equipment/' + treeId,
                offline: true,
               	data: {_method: 'DELETE'}
            });
        }
    };
});
