angular.module('contactsCreateController', []).controller('contactsCreateController', function($scope, Contact) {
    
	/**
	 * Initial data
	 */
    $scope.loadingGlobal = false;

	$scope.$on('noContactsFound', function (e) {
		$scope.new_contact = 1;
	});

	/**
	 * Model actions
	 */
    $scope.create = function() {
        $scope.loadingGlobal = true;
		_.assign($scope.contact, {
			client_id: $scope.$parent.client ? $scope.$parent.client.id : $scope.$parent.workplace.client_id
		});

		if ($scope.$parent.workplace) {
			_.assign($scope.contact, {
				workplace_id: $scope.$parent.workplace.id
			});
		}

        Contact.create($scope.contact)
            .success(function(data) {
                $scope.contact = {};
                $scope.createContactForm.$setUntouched();
                $scope.errors = undefined;
				if ($scope.$parent.workplace) {
					$scope.$parent.workplace.contacts.push(data);
					$scope.$parent.workplace.contacts.sort(function (a, b) {
						return a.last_name === b.last_name ? 0 : a.last_name < b.last_name ? -1 : 1;
					});
				} else {
					$scope.$parent.contacts.push(data);
					$scope.$parent.contacts.sort(function (a, b) {
						return a.last_name === b.last_name ? 0 : a.last_name < b.last_name ? -1 : 1;
					});
				}

                $('.alert.alert-success').fadeIn().delay(2000).fadeOut();
                $('#addContact').modal('hide');
            })
            .error(function(data) {
                $scope.errors = data;
                $('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
            })
			.finally(function() { $scope.loadingGlobal = false; });
    };

	// Create contact from notifier modal
	$scope.createFromNotifier = function() {
		$scope.loadingGlobal = true;
		_.assign($scope.contact, {
			client_id: $scope.$parent.ticket ? $scope.$parent.ticket.client_id : $scope.$parent.report.client_id,
			workplace_id: $scope.$parent.ticket ? $scope.$parent.ticket.workplace_id : $scope.$parent.report.workplace_id
		});
		Contact.create($scope.contact)
			.success(function(data) {
				$scope.contact = {};
				$scope.new_contact = 0;
				$scope.createContactForm.$setUntouched();
				$scope.errors = undefined;
				$scope.$parent.contacts.push(data);
				// re-sort contacts
				$scope.$parent.contacts.sort(function (a, b) {
					return a.last_name === b.last_name ? 0 : a.last_name < b.last_name ? -1 : 1;
				});
				$('.alert.alert-success').fadeIn().delay(2000).fadeOut();
			})
			.error(function(data) {
				$scope.errors = data;
				$('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
			})
			.finally(function() { $scope.loadingGlobal = false; });
	};
});