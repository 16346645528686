angular.module('commentService', []).factory('Comment', function($http) {

    return {
        create: function(data) {
            return $http({
                method: 'POST',
                url: 'comments',
                offline: true,
                data: data
            });
        },
        update: function(id, data) {
            return $http({
                method: 'POST',
                url: 'comments/' + id,
                offline: true,
                data: _.assign({ _method: 'PATCH' }, data)
            });
        },
        destroy: function(id) {
            return $http({
                method: 'POST',
                url: 'comments/' + id,
                offline: true,
                data: {_method: 'DELETE'}
            });
        },
    };
});
