angular.module('uploadController', []).controller('uploadController', function($scope, $translate, $timeout, Upload, Document, Alert, uuid4, upload_max_filesize, mime_types_allowed, image_max_width) {
    
    var pendingDocuments = [];

    var getDocumentFactory = function() {
        return {
            id: uuid4.generate(),
            name: undefined,
            progressPercentage: 0.00, 
            uploaded: false,
            size: undefined,
            url: undefined
        };
    };
    
    var getEmptyDocument = function() {
        return  {
            name: '',
            label: '#FFFFFF',
            file: undefined
        };
    };
    
    var onProgressFile = function(e) {
        var progressPercentage = parseInt(100.0 * e.loaded / e.total);
        var document = _.find(pendingDocuments, {id: e.config.file.id});
        document.progressPercentage = progressPercentage;
    };
    
    var onSuccessFile = function(data, status, headers, config) {
        var document = _.find(pendingDocuments, {id: config.file.id});
        document.uploaded = true;
        document.url = data.filename;
        $scope.$emit('documentAdded', document);        
        $scope.document = getEmptyDocument();
    };
    
    var onErrorFile = function(data, status, headers, config) {
        var document = _.find(pendingDocuments, {id: config.file.id});        
        document.name = $translate.instant('failDocument');        
        $timeout(function() { $scope.$emit('documentDestroyed', document) }, 3000);        
        $scope.document = getEmptyDocument();
    };

    $scope.$watch('file', function () {
        $scope.upload($scope.file);
        $scope.uploadDone = true;
    });
    
    $scope.document = getEmptyDocument();    
    $scope.upload_max_filesize = upload_max_filesize;
    $scope.mime_types_allowed = mime_types_allowed;
    $scope.image_max_width = image_max_width;
    $scope.uploadDone = false;
    
    $scope.upload = function (file) {
        if (file != null) {    
            var document = getDocumentFactory();
            document.name = $scope.document.name;
            document.label = $scope.document.label;
            document.size = file.size;
            
            pendingDocuments.push(document);
            file.id = document.id; // bind file to document by id
            
            Document.create(file, $scope.filename)
                .progress(onProgressFile)
                .success(onSuccessFile)
                .error(onErrorFile);

           
        }
    };
    
    $scope.destroyDocument = function (document) {
        Alert.confirm(function() { return Document.destroy(document.url) }, function() {
            $scope.$emit('documentDestroyed', document);
        });
    };

    $scope.preUpload = function (file){
        if (!$scope.document.name) {
            $scope.document.name = file.name.replace(/\.[^/.]+$/, "");
        }
    }
});