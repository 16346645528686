angular.module('loadingService', []).factory('Loading', function($timeout) {
    
    var pending = {};
    
    return {
        register: function(key, promise, success) {
            pending[key] = true;
            return promise
                .success(success)
                .error(function(data) { console.log(data) })
                .finally(function() { 
                    delete pending[key];
                    $timeout(setNanoScroller, 1000);
                });
        },
        hasLoaded: function(key) {
            return !_.has(pending, key);
        }
    };
});
