angular.module('reportsEditController', []).controller('reportsEditController', function($scope, $timeout, $builder, $filter, $location, $validator, Report, Operation, Remark, Route, Alert, Draft, DirtyModel, Form, User) {
    
    /**
	 * Initial data
	 */
	
    $scope.operations = [];
    $scope.remarks = [];
    $scope.forms = [];
    $scope.users = [];
    $scope.filter = $filter('uiTreeFilter');
    $scope.options = {
        accept: function(sourceNodeScope, destNodesScope, destIndex) {
            return true;
        }
    };
    
    var currentId = Route.getAction()[1];
    var currentNode;

    Form.loadComponents();
    
	/**
	 * Utils
	 */
    
    var populateForm = function(source) {
        var tempForm = null

        if ($scope.operation) {
            tempForm = $scope.operation.form;
        } else {
            tempForm = _.find($scope.forms, {_id: $scope.item.form_id});
        }

        source.input.forEach(function (input, index) { 
            var field = tempForm.fields[index];
            if (field.component === 'checkbox') {
                var textToBooleanArray = [];
                var arrayValue  = input.value.split(', ');
                field.options.forEach(function(option) {
                    textToBooleanArray.push(_.contains(arrayValue, option));
                });
                $scope.defaultValue[input.id] = textToBooleanArray;
            }
            else {
                $scope.defaultValue[input.id] = input.value;
            }
        });
    };

    /**
     * Drafts
     */

    $scope.recoverDraft = function() {
        Draft.recover($scope);
        populateForm($scope.input_operation); // re-fill default values
    };
    
    /**
	 * Get asynchronous data
	 */
	
	$scope.loadingGlobal = true;
    Report.get(currentId)
        .success(function (data) { 
            $scope.report = data;
            $scope.list = data.equipment_tree; 
        })
        .finally(function() { $scope.loadingGlobal = false; });
    Operation.get(currentId)
        .success(function (data) { $scope.operations = data;  })
        .finally(function() { $scope.loadingGlobal = false; });

    $scope.getReportRemarks = function () {
        Remark.get(currentId)
            .success(function (data) { $scope.remarks = data; })
            .finally(function() { $scope.loadingGlobal = false; });
    };
    
    Form.get()
        .success(function (data) { $scope.forms = data; })
        .finally(function() { $scope.loadingGlobal = false; });
        
    $scope.form = $builder.forms['default']; // initialize form

    User.get().then(function(response){
        $scope.users = response.data;
    });
          
    /**
     * Node selection
     */ 

    $scope.checkIfActive = function(scope) {
        $timeout(function() {
            if ($location.path().split('/')[1] === scope.$modelValue._id) {
                $scope.selectItem(scope);
            }
        }, 200);
    };

    $scope.isNodeSelected = function(node) {
        return node === currentNode;
    };

    $scope.hasOperation = function(node) {
        return _.find($scope.operations, {equipment_id: node.$modelValue._id}) != null;
    };

    $scope.hasRemark = function(node) {
        return _.find($scope.remarks, {equipment_id: node.$modelValue._id}) != null;
    };

    $scope.isSelectable = function(node) {
        return node.$modelValue.form_id != null;
    };

    $scope.selectItem = function(scope) {
        if ($scope.isSelectable(scope)) {
            
            DirtyModel.unwatch('input_operation');
        
            currentNode = scope;
            $scope.item = scope.$modelValue;
            $location.path($scope.item._id);
            
            // remove current form
            $scope.form.length = 0;
            $scope.input_operation = {
                _id: $scope.item._id,
                input: []
            }
            $scope.defaultValue = {};
            
            // get operation and remark data
            $scope.operation = _.find($scope.operations, {equipment_id: $scope.item._id});
            $scope.remark    = _.find($scope.remarks, {equipment_id: $scope.item._id});

            // display form data
            var form = null
            if ($scope.operation) {
                form = $scope.operation.form;
            } else {
                form = _.find($scope.forms, {_id: $scope.item.form_id});
            }

            $scope.title = form.title;
            form.fields.forEach(function (field) {
                $builder.addFormObject('default', field);
            });
            
            // display operation data
            if ($scope.operation != null) {
                populateForm($scope.operation);
            }

            // get the draft for this item and reset watcher
            Draft.get('input_operation', $scope.item._id)
                .success(function(data) {
                    $scope.draft = _.isEmpty(data) ? null : data; 
                    $scope.displayDraftPanel = !!$scope.draft;
                    DirtyModel.reset($scope, 'input_operation');
                })
                .error(function(data) { console.log(data); });
        }
    };
    
    /**
	 * Model actions
	 */
    
    $scope.destroyRemark = function () {
        Alert.confirm(function() { return Remark.destroy($scope.remark._id) }, function() {
            _.remove($scope.remarks, function (item) { return item._id === $scope.remark._id; });
            $scope.remark = null;
        });
    };
 
    $scope.submit = function() {

        var operation = {
            equipment_id: $scope.item._id,
            form_id:      $scope.item.form_id,
            report_id:    currentId,
            input:        $scope.input_operation.input
        };

        $validator.validate($scope, 'default').success(function() {

            $scope.loadingGlobal = true; 
            
            ( ($scope.operation != null) ? Operation.update($scope.operation._id, operation) : Operation.create(operation) )
                .success(function (data) {
                    $scope.loadingGlobal = false;
                    DirtyModel.reset($scope, 'input_operation');
                    $('.alert.alert-success').fadeIn().delay(2000).fadeOut();
                    // update operations collection
                    ($scope.operation != null) ? $scope.operations.splice(_.indexOf($scope.operations, $scope.operation), 1, data) : $scope.operations.push(data);
                    $scope.operation = data;
                })
                .error(function (data) {
                    console.log(data); 
                    $scope.loadingGlobal = false;
                    $('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
                });
            })
            .error(function() { console.log('error'); });
    };
       
    /**
     * Document listeners
     */
    
    $scope.$on('documentAdded', function (e, document) {
        
        // initialize value as array when the operation does not exist 
        if (!_.isArray(e.targetScope.$parent.inputText)) {
            e.targetScope.$parent.inputText = [];
        }
        
        e.targetScope.$parent.inputText.push(document);
        
        // add default value to form when the operation does not exist 
        if ($scope.defaultValue[e.targetScope.$parent.id] === undefined) {
            $scope.defaultValue[e.targetScope.$parent.id] = e.targetScope.inputText;
        }
        
        $('#addDocument' + e.targetScope.$id).modal('hide');
    });
    
    $scope.$on('documentDestroyed', function (e, document) {
        _.remove(e.targetScope.$parent.inputText, function (doc) { return doc.id === document.id; });   
        $timeout($scope.submit);     
    });
});