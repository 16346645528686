angular.module('clientsEditController', []).controller('clientsEditController', function($scope, $window, Client, Route, Loading) {
    
	/**
	 * Initial data
	 */
	
    var currentId = Route.getAction()[1];
    
    /**
	 * Get asynchronous data
	 */
	
    $scope.loadingGlobal = true;
	Client.get(currentId)
        .success(function (data) { $scope.client = data; })
        .finally(function() { $scope.loadingGlobal = false; });
    
    /**
     * Document listeners
     */
    
    $scope.$on('documentAdded', function (e, document) {
        $scope.client.logo_url = document.url;
        Client.update($scope.client.id, $scope.client)
            .success(function(data) {
                $scope.errors = undefined;
                $window.location = './clients/' + $scope.client.id + '/edit';
            })
            .error(function(data) {
                console.log(data);
                $scope.errors = data;
                $('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
            })
            .finally(function() { $scope.loadingGlobal = false; });
    });
    
    $scope.$on('documentDestroyed', function (e, document) {
        $scope.client.logo_url = null;
        Client.update($scope.client.id, $scope.client)
            .success(function(data) {
                $scope.errors = undefined;
                $window.location = './clients/' + $scope.client.id + '/edit';
            })
            .error(function(data) {
                console.log(data);
                $scope.errors = data;
                $('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
            })
            .finally(function() { $scope.loadingGlobal = false; });
    });
        
	/**
	 * Model actions
	 */
	
    $scope.update = function() {
        $scope.loadingGlobal = true;
        Client.update($scope.client.id, $scope.client)
            .success(function(data) {
                $scope.errors = undefined;
                $window.location = './clients#/' + $scope.client.id;
            })
            .error(function(data) {
                console.log(data);
                $scope.errors = data;
                $('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
            })
			.finally(function() { $scope.loadingGlobal = false; });
    };
});