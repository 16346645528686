angular.module('reportsIndexController', []).controller('reportsIndexController', function($scope, $window, Report, Client, Workplace, Alert, Filter, filterFilter, Loading, Contact) {
    
	/**
	 * Initial data
	 */
	var data = {};
	$scope.reports = [];
	$scope.contacts = [];
	$scope.search = {
		designation: '',
		title: '',
		state: '',
		archived: '',
		user: {
			login: ''
		},
		client: {
			designation: ''
		},
		workplace: {
			designation: ''
		}
	};
	$scope.searchPeriod = {
		created_at: '',
		finished_at: ''
	};
	
	/**
	 * Filters
	 */
	
	Filter.enable($scope, 'search');
	Filter.enable($scope, 'searchPeriod', true);

	/**
	 * STATS
	 */
	$scope.stats = {
		'opened': 0,
		'finished': 0,
		'forwarded': 0
	}
	
	/**
	 * Utils
	 */
    var filterTrashed = function(collection) {
        return _.filter(collection, {isTrashed: false});
    }; 
	
	$scope.countBy = function(collection, state) {
		return (state != null) ? _.filter(collection, {state: state}).length : collection.length;
	};
	
	$scope.timestampToDate = function(timestamp) {
		return new Date(timestamp);	
	};
	
	$scope.getWorkplaces = function() {
		return _.isEmpty($scope.search.client.designation) ? $scope.workplaces : _.find($scope.clients, {designation: $scope.search.client.designation}).workplaces;
	};

	/**
	 * PAGINATION
	 */
	$scope.pagination = {}

	var formatPagination = function(queryResult) {
		if (queryResult) {
			$scope.pagination = {
				current: queryResult.current_page,
				last: queryResult.last_page == queryResult.current_page ? null : queryResult.last_page,
				previous: queryResult.current_page == 1 ? null : (queryResult.current_page - 1),
				next: queryResult.last_page == queryResult.current_page  ? null : (queryResult.current_page + 1)
		  	}
	
		  	$scope.pagination.range = formatPaginationRange()
		} else {
		  	$scope.pagination = {
				current: null,
				last: null,
				previous: null,
				next: null,
				range: []
		  	}
		}    
	}

	/**
	 * Construct range [1,2,3]
	 * @param pagination 
	 */
  	var formatPaginationRange = function() {
		var range = [];
		let limit = $scope.pagination.last ? $scope.pagination.last : $scope.pagination.current
		for(var i = -2 ; i < 3; i ++) {
			var pageNumber = $scope.pagination.current + i
			if (pageNumber > 0 && pageNumber <= limit) {
				range.push(pageNumber)
			}
		}
		return range;
	}

	/**
	 * Changement de page
	 */
	$scope.goToPage = function(page) {
		$scope.getReports(page)
	}
	
	/**
	 * Get asynchronous data
	 */
	// Récupération des rapports
	$scope.getReports = function(page, callback) {
		Loading.register('reports', Report.paginate(page, $scope.search, $scope.searchPeriod), function (response) {
			$scope.reports = response.data;
			formatPagination(response);

			if(callback) {
				callback()
			}
		});

		Report.counts($scope.search, $scope.searchPeriod).success(function(data) { $scope.stats = data });

	};

	$scope.getReports(1, function() {
		let searchParams = new URLSearchParams($window.location.href);
		let reportIdToNotify = searchParams.get('notify');

		if(reportIdToNotify) {
			let report = _.find($scope.reports, {id: parseInt(reportIdToNotify)});
			if (report) {
				$scope.openNotifyForm(report)
			}
		}
	});

	$scope.getClients = function(val) {
		return Client.getDesignations({designation: val}).then(function(response){
			return response.data
		});
	};

	$scope.getWorkplaces = function(val) {
		return Workplace.getDesignations({designation: val, client: $scope.search.client.designation}).then(function(response){
			return response.data
		});
	};

	// Réinitialisation des filtres
	$scope.resetFilters = function() {
		$scope.filter.reset($scope.search); 
		$scope.filter.reset($scope.searchPeriod);

		setTimeout(function(){
			$scope.getReports(1);
		}, 1000);
	}

	/**
	 * Model actions
	 */
	
    $scope.destroy = function(id) {
        Alert.confirm(
            function() { return Report.destroy(id) }, 
            function() { _.remove($scope.reports, function (model) { return model.id === id; })}
        );
    };
	
	$scope.open = function(report) {
		Alert.reopen(
			function() { return Report.open(report.id) },
			function() { report.state = 'opened';report.finished_at = null; }
		);
	};
	
	$scope.forward = function(report) {
		$scope.loadingGlobal = true;
		_.assign(data, {report_id: report.id, contacts: $scope.contacts});
		$('#notify' + report.id).modal('hide');

		Report.forward(data)
			.success(function(data) {
				report.state = 'forwarded';
				$('.alert.alert-success').fadeIn().delay(2000).fadeOut();
			})
			.error(function(data) {
				console.log(data);
				$('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
			})
			.finally(function() { $scope.loadingGlobal = false; });
	};

	$scope.openNotifyForm = function (report) {
		Contact.get(report.client_id)
			.success(function (data){
				$scope.contacts = data;
				if (data.length === 0) {
					$scope.$broadcast('noContactsFound');
				}
			})
			.error(function (data) { console.log(data); })
			.finally(function () { $('#notify' + report.id).modal('show'); });
	};

	/**
	 * Export
	 */
	$scope.export = function (format) {
		Report.export(format, $scope.search, $scope.searchPeriod).success(function(data, status, headers){
			headers = headers();
 
			var filename = 'export_reports_' + moment().format('DD/MM/YYYY');
			var contentType = headers['content-type'];
	 
			var linkElement = document.createElement('a');
			try {
				var blob = new Blob([data], { type: contentType });
				var url = window.URL.createObjectURL(blob);
	 
				linkElement.setAttribute('href', url);
				linkElement.setAttribute("download", filename);
	 
				var clickEvent = new MouseEvent("click", {
					"view": window,
					"bubbles": true,
					"cancelable": false
				});
				linkElement.dispatchEvent(clickEvent);
			} catch (ex) {
				console.log(ex);
			}
		});
	};
});