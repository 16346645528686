angular.module('filterService', []).factory('Filter', function($location, $timeout) {

    return {
            
        enable: function(scope, filters, dateFilters) {
            this.getFiltersFromQueryString(scope[filters], dateFilters);
            this.watch(scope, filters);
        },
        getFiltersFromQueryString: function(filter, dateFilters) {
            var qs = $location.search();
            for (var param in filter) {
                if (_.isPlainObject(filter[param])) {
                    param = param + '.' + _.first(_.keys(filter[param]));
                }
                if (param in qs) {
                    if (_.contains(param, '.')) {
                        var params = param.split('.');
                        filter[params[0]][params[1]] = qs[param];
                    }
                    else {
                        filter[param] = dateFilters ? moment(qs[param], 'dddd LL').format('dddd LL') : qs[param];
                    }
                }
            }
        },
        reset: function(filter, key) {
            if (key != null) {
                if (_.contains(key, '.')) {
                    var keys = key.split('.');
                    filter[keys[0]][keys[1]] = '';
                }
                else  {
                    filter[key] = _.isDate(filter[key]) ? null : '';
                }
            }
            else {
                for (var param in filter) {
                    if (_.isPlainObject(filter[param])) { // allow only one nested object
                        for (var _param in filter[param]) {
                            filter[param][_param] = '';
                        }
                    }
                    else {
                        filter[param] = '';
                    }
                }
            }
        },
        watch: function(scope, filters) {
            scope.$watch(filters, function (filter) {
                for (var param in filter) {
                    if (_.isPlainObject(filter[param])) { // allow only one nested object
                        for (var _param in filter[param]) {
                            var value = filter[param][_param];
                            $location.search(param + '.' + _param, _.isEmpty(value) ? null : value);
                        }
                    }
                    else {
                        var value = _.isDate(filter[param]) ? filter[param].toISOString() : filter[param];
                        $location.search(param, _.isEmpty(value) ? null : value);
                    }
                }
            }, true);
        },
        isFilterActive: function() {
            return !_.isEmpty($location.search());
        },
        dateRangeFilter: function(filters) {
            return function (item) {
                var start = filters.created_at.length == 0 ? 0 : moment(filters.created_at, 'dddd LL').format('x');
                var end = filters.finished_at.length == 0 ? Number.MAX_VALUE : moment(filters.finished_at, 'dddd LL').format('x');
                return (start <= item.created_at && (item.finished_at || item.created_at) <= end); // compare to created_at and finished_at or only on created_at attribute
            }
        }
    }; 
});