angular.module('reportsCreateController', []).controller('reportsCreateController', function($scope, $window, Report, Workplace, Loading, User) {
   
   /**
    * Initial data
    */
    
    $scope.loadingGlobal = false;
    $scope.continue = true;
    $scope.users = [];

   	/**
	 * Utils
	 */
	
    $scope.filterTrashed = function(collection) {
        return _.filter(collection, {isTrashed: false});
    }; 

    $scope.getEquipmentTree = function (id) {
		Loading.register('workplace', Workplace.get(id), function (data) {
			$scope.equipment_tree = data.equipment_tree;
		});
	};

    User.get().then(function(response){
        $scope.users = response.data;
    });

	/**
	 * Model actions
	 */
    
	$scope.create = function($event) {
        $scope.$parent.hideSubmitButton($event);
		$scope.loadingGlobal = true;
		Report.create({equipment_tree_id: $scope.equipment_tree._id, title: $scope.title, user_id: $scope.user_id})
			.success(function(report) {
                $scope.$parent.reports.push(report);
                if ($scope.continue) {
                    $window.location = './reports/' + report.id + '/edit';
                }
            })
			.error(function(data) {
                $('.alert.alert-error.license').fadeIn();
            })
			.finally(function() {
                $('#addReport')
                    .modal('hide')
                    .on('hidden.bs.modal', function() {
                        $scope.selectedClient = undefined;
                        $scope.selectedWorkplace = undefined;
                        $scope.loadingGlobal = false; 
                    });
            }); 
	};
});
