angular.module('signatureController', []).controller('signatureController', function($scope, Signature, Route) {
    
    /**
     * Check for controller action and id
     */

    var currentId = Route.getAction()[1];
    
    /**
     * Upload a signature 
     */
    
    $scope.done = function(type) {
        $scope.loadingGlobal = true;
        Signature.createOrUpdate(currentId, {data_url: $scope.accept().dataUrl, type: (type === 'operator') ? 1 : 2})
            .success(function (data) {
                $scope.loadingGlobal = false;
                $scope.$parent.report.signatures = data;
                $('.alert.alert-success').fadeIn().delay(2000).fadeOut();
                $('#signReport').modal('hide');
            })
            .error(function(data) {
                $scope.loadingGlobal = false;
                $('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
            });
    };
});