angular.module('timelineController', []).controller('timelineController', function($scope, User) {

	$scope.labels = [];
	$scope.data = [[]];

	User.stats().success(function (data) {
		data.forEach(function (stat) {
			$scope.labels.push(stat.login);
			$scope.data[0].push(stat.nb_actions);
		});
	});
});