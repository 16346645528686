angular.module('contactsEditController', []).controller('contactsEditController', function($scope, Contact) {
    
	/**
	 * Initial data
	 */
	
    $scope.loadingGlobal = false;

	/**
	 * Model actions
	 */
	
    $scope.update = function() {
        $scope.loadingGlobal = true;
		if ($scope.$parent.workplace) {
			_.assign($scope.contact, {
				workplace: $scope.$parent.workplace.designation
			});
		}

        Contact.update($scope.contact.id, $scope.contact)
            .success(function(data) {
                $scope.errors = undefined;
                $('.alert.alert-success').fadeIn().delay(2000).fadeOut();
                $('#editContact' + $scope.contact.id).modal('hide');
            })
            .error(function(data) {
                $scope.errors = data;
                $('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
            })
			.finally(function() { $scope.loadingGlobal = false; });
    };
});