angular.module('workplacesEditController', []).controller('workplacesEditController', function($scope, $window, Workplace, Route, Entrust, Alert, Contact) {
    
	/**
	 * Initial data
	 */
	
    var currentId = Route.getAction()[1];
    
    /**
	 * Get asynchronous data
	 */
	
    $scope.loadingGlobal = true;
	Workplace.get(currentId)
        .success(function (data) { $scope.workplace = data; })
        .finally(function() { $scope.loadingGlobal = false; });

	/**
	 * Model actions
	 */
	
    $scope.update = function() {
        $scope.loadingGlobal = true;
        Workplace.update($scope.workplace.id, $scope.workplace)
            .success(function(data) {
                $scope.errors = undefined;
                $('.alert.alert-success').fadeIn().delay(2000).fadeOut();
            })
            .error(function(data) {
                $scope.errors = data;
                $('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
            })
			.finally(function() { $scope.loadingGlobal = false; });
    };
});