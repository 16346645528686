angular.module('homeController', []).controller('homeController', function($scope, $translate, Report, Remark, Ticket, Loading) {

	/**
	 * Initial data
	 */
	
	$scope.remarks = [];
	$scope.reports = [];
	$scope.tickets = [];
	$scope.ticketsResponsable = [];
	$scope.labels = [];

	$scope.$emit('tab', 'reports');
	
	/**
	 * Get asynchronous data
	 */
	Loading.register('reports', Report.getLast(10), function (data) { $scope.reports = data; });


	/**
	 * Stats
	 */
	
    var date = new Date();
    var currentMonth = date.getMonth();
    var currentYear = date.getFullYear();
    var months = _.range(0, 12).concat(_.range(0, 12));
    var position = months.lastIndexOf(currentMonth);
    var positionTable = {};

    months.slice(position - 11, position + 1).forEach(function (month, index) {
        var year = (currentMonth - month) < 0 ? currentYear - 1 : currentYear;
    	$scope.labels.push($translate.instant('datepicker.monthsFull.' + month).substr(0, 3) + '. ' + year.toString().substr(2, 4));
        positionTable[month] = index;
    });

	$scope.reports_data = new Array(_.fill(Array(12), 0));

	Report.stats().success(function (data) {
		$scope.reports_data[0] = data;
	});

	$scope.getRemarksStats = function () {
		Loading.register('remarks', Remark.getLast(10), function (data) { $scope.remarks = data; });
		$scope.remarks_data = new Array(_.fill(Array(12), 0));

		Remark.stats().success(function (data) {
			data.forEach(function (stat) {
				$scope.remarks_data[0][positionTable[stat.month]] = parseInt(stat.total, 10);
			});
		});
	};

	$scope.getTicketsStats = function () {
		Loading.register('tickets', Ticket.getLast(10), function (data) { $scope.tickets = data; });
		Loading.register('tickets-responsable', Ticket.getResponsable(), function (data) { $scope.ticketsResponsable = data; });
		$scope.tickets_data = new Array(_.fill(Array(12), 0));

		Ticket.stats().success(function (data) {
			$scope.tickets_data[0] = data;
		});
	};
});