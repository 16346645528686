angular.module('ticketsEditController', []).controller('ticketsEditController', function($scope, $window, Ticket, Route, Entrust, Client, Workplace, Loading, User) {

	/**
	 * Initial data
	 */
	$scope.continue = true;
	$scope.selectedItem = null;
	$scope.users = [];

	// Auto complete all informations of ticket
	$scope.$on('editTicket', function (e, ticket) {
		$scope.ticket = angular.copy(ticket);
		$scope.selectedItem = ticket.equipment_id;
		$scope.clients = [$scope.ticket.client]

		// Get equipment tree
		$scope.getEquipmentTree($scope.ticket.workplace.id);

		// Client selection
		angular.forEach($scope.clients, function(client, key) {
			if (ticket.client_id === client.id) {
				$scope.selectedClient = client;

				// Workplce selection
				angular.forEach(client.workplaces, function(workplace, key) {
					if (ticket.workplace_id === workplace.id) {
						$scope.selectedWorkplace = workplace;
					}
				});
			}
		});
	});

	/**
	 * Utils
	 */
	$scope.filterTrashed = function(collection) {
		return _.filter(collection, {isTrashed: false});
	};

	$scope.getClients = function(val) {
		return Client.getDesignations({designation: val}).then(function(response){
			return response.data
		});
	};

	Workplace.getDesignations().success(function(data) { $scope.workplaces = data });

	User.get().then(function(response){
		$scope.users = response.data;
	});

	$scope.options = {
		accept: function(sourceNodeScope, destNodesScope, destIndex) {
			return true;
		}
	};

	$scope.selectItem = function(item) {
		if (item._id === $scope.selectedItem) {
			$scope.unSelectItem();
		} else {
			$scope.ticket.equipment_id = item._id;
			$scope.selectedItem = item._id;
		}
	};

	$scope.isItemSelected = function(item) {
		return item._id === $scope.selectedItem;
	};

	$scope.unSelectItem = function() {
		$scope.ticket.equipment_id = null;
		$scope.selectedItem = null;
	};

	$scope.getEquipmentTree = function (id) {
		Loading.register('workplace', Workplace.get(id), function (data) {
			$scope.equipment_tree = data.equipment_tree;
		});
	};

	/**
	 * Model actions
	 */
	$scope.edit = function(form) {
		_.assign($scope.ticket, {
			workplace_id: form.workplace.$modelValue.id,
			client_id: form.client.$modelValue.id
		});

		Ticket.update($scope.ticket._id, $scope.ticket)
			.success(function(ticket) {
				$window.location.reload();
			})
			.error(function(data) {
				console.log(data);
				$('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
			})
			.finally(function() { $scope.loadingGlobal = false; });
	};
});