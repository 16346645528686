angular.module('draftService', []).factory('Draft', function($http) {

    return {

        recover: function(scope) {
            scope[scope.draft.model] = scope.draft.data;
            scope.displayDraftPanel = false;
        },
        get: function(model, id) {
            return $http({
                method: 'GET',
                url: 'drafts/' + model + (id != null ? '/' + id : ''),
                offline: true
            });
        },
        create: function(data) {
            return $http({
                method: 'POST',
                url: 'drafts',
                offline: true,
                data: data
            });
        },
        update: function(id, data) {
            return $http({
                method: 'POST',
                url: 'drafts/' + id,
                offline: true,
                data: _.assign({ _method: 'PATCH' }, {data: data})
            });
        },
        destroy: function(id) {
            return $http({
                method: 'POST',
                url: 'drafts/' + id,
                offline: true,
                data: {_method: 'DELETE'}
            });
        },
    };
});