angular.module('signatureService', []).factory('Signature', function($http) {

    return {

      	createOrUpdate: function(reportId, data) {
            return $http({
                method: 'POST',
                url: 'reports/' + reportId + '/sign',
                offline: true,
                data: data
            });
        }
    };
});
