angular.module('workplaceService', []).factory('Workplace', function($http) {

    /**
	 * Construction chaine de caractère pour filtres
	 * @param filters 
	 */
  	var formatFilters = function(filters) {
        var string = ''
        if (filters) {
            if (filters.designation) { string += '&designation=' + filters.designation }
            if (filters.client) { string += '&client=' + filters.client }
        }
        return string;
    }

    return {

        get: function(id) {
            return $http({
                method: 'GET',
                url: 'workplaces' + (id != null ? '/' + id : ''),
                offline: true
            });
        },
        create: function(data) {
            return $http({
                method: 'POST',
                url: 'workplaces',
                offline: true,
                data: data
            });
        },
       	update: function(id, data) {
            return $http({
                method: 'POST',
                url: 'workplaces/' + id,
                offline: true,
                data: _.assign({ _method: 'PATCH' }, data)
            });
        },
        destroy: function(id) {
            return $http({
                method: 'POST',
                url: 'workplaces/' + id,
                offline: true,
                data: {_method: 'DELETE'},
            });
        },
        getDesignations: function(filters) {
            return $http({
                method: 'GET',
                url: 'api/workplaces/get_designations?filters' + formatFilters(filters),
                offline: true
            });
        }
    };
});
