angular.module('formService', []).factory('Form', function($http, $translate, $builder) {

    return {

        get: function(id) {
            return $http({
                method: 'GET',
                url: 'forms' + (id != null ? '/' + id : ''),
                offline: true
            });
        },
        create: function(data) {
            return $http({
                method: 'POST',
                url: 'forms',
                offline: true,
                data: data
            });
        },
        update: function(id, data) {
            return $http({
                method: 'POST',
                url: 'forms/' + id,
                offline: true,
                data: _.assign({ _method: 'PATCH' }, data)
            });
        },
        destroy: function(id) {
            return $http({
                method: 'POST',
                url: 'forms/' + id,
                offline: true,
                data: {_method: 'DELETE'},
            });
        },        
        getWithOperationsAndRevisions: function(id) {
            return $http({
                method: 'GET',
                url: 'api/forms/' + id + '/get_with_revisions_operations',
                offline: true
            });
        },
        getEquipmentsFromForm: function(id) {
            return $http({
                method: 'GET',
                url: 'api/forms/' + id + '/get_equipments',
                offline: true
            });
        },
        getDefault: function() {
            return $http({
                method: 'GET',
                url: 'forms/default',
                offline: true
            });
        },

        loadComponents: function() {
            $builder.registerComponent('file', {
                group: $translate.instant('formbuilder.group.special'),
                label: $translate.instant('formbuilder.file.label'),
                description: $translate.instant('formbuilder.file.description'),
                required: false,
                validation: '*',
                validationOptions: [
                    {
                        label: $translate.instant('formbuilder.file.validationOptions.all'),
                        rule: '*'
                    },
                    {
                        label: $translate.instant('formbuilder.file.validationOptions.image'),
                        rule: 'image/*'
                    },
                    {
                        label: $translate.instant('formbuilder.file.validationOptions.video'),
                        rule: 'video/*'
                    },
                    {
                        label: $translate.instant('formbuilder.file.validationOptions.pdf'),
                        rule: '.pdf'
                    },
                    {
                        label: $translate.instant('formbuilder.file.validationOptions.word'),
                        rule: '.doc,.docx'
                    },
                    {
                        label: $translate.instant('formbuilder.file.validationOptions.excel'),
                        rule: '.xls,.xlsx'
                    },
                    {
                        label: $translate.instant('formbuilder.file.validationOptions.archive'),
                        rule: '.zip,.rar,.tar.gz'
                    }
                ],
                templateUrl: 'ng-templates/forms/file',
                popoverTemplateUrl: 'ng-templates/forms/file_popover'
            });
            
            $builder.registerComponent('datepicker', {
                group: $translate.instant('formbuilder.group.special'),
                label: $translate.instant('formbuilder.datepicker.label'),
                description: $translate.instant('formbuilder.datepicker.description'),
                required: false,
                placeholder: $translate.instant('formbuilder.datepicker.placeholder'),
                templateUrl: 'ng-templates/forms/datepicker',
                popoverTemplateUrl: 'ng-templates/forms/datepicker_popover'
            });
            
            $builder.registerComponent('text', {
                group: $translate.instant('formbuilder.group.special'),
                label: $translate.instant('formbuilder.text.label'),
                description: $translate.instant('formbuilder.text.description'),
                required: false,
                templateUrl: 'ng-templates/forms/text',
                popoverTemplateUrl: 'ng-templates/forms/text_popover'
            });
            
            $builder.registerComponent('severity', {
                group: $translate.instant('formbuilder.group.special'),
                label: $translate.instant('formbuilder.severity.label'),
                description: $translate.instant('formbuilder.severity.description'),
                templateUrl: 'ng-templates/forms/severity',
                popoverTemplateUrl: 'ng-templates/forms/severity_popover'
            });
        
            $builder.registerComponent('chart', {
                group: $translate.instant('formbuilder.group.special'),
                label: $translate.instant('formbuilder.chart.label'),
                description: $translate.instant('formbuilder.chart.description'),
                options: [$translate.instant('formbuilder.chart.x_axis'), $translate.instant('formbuilder.chart.y_axis'), 5, null],
                templateUrl: 'ng-templates/forms/chart',
                popoverTemplateUrl: 'ng-templates/forms/chart_popover'
            });
        
            $builder.registerComponent('array', {
                group: $translate.instant('formbuilder.group.special'),
                label: $translate.instant('formbuilder.array.label'),
                description: $translate.instant('formbuilder.array.description'),
                options: [2, 2, '-', '-'],
                templateUrl: 'ng-templates/forms/array',
                popoverTemplateUrl: 'ng-templates/forms/array_popover'
            });
        
            $builder.registerComponent('lineBreak', {
                group: $translate.instant('formbuilder.group.special'),
                label: $translate.instant('formbuilder.lineBreak.label'),
                description: $translate.instant('formbuilder.lineBreak.description'),
                options: [1],
                required: false,
                templateUrl: 'ng-templates/forms/lineBreak',
                popoverTemplateUrl: 'ng-templates/forms/lineBreak_popover'
            });
        
            $builder.registerComponent('pageBreak', {
                group: $translate.instant('formbuilder.group.special'),
                label: $translate.instant('formbuilder.pageBreak.label'),
                description: $translate.instant('formbuilder.pageBreak.description'),
                required: false,
                templateUrl: 'ng-templates/forms/pageBreak',
                popoverTemplateUrl: 'ng-templates/forms/pageBreak_popover'
            });
        }
    };
});
