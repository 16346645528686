angular.module('ticketsIndexController', []).controller('ticketsIndexController', function($scope, $window, Filter, filterFilter, Ticket, Client, Workplace, Loading, Entrust, User) {
    
	/**
	 * Initial data
	 */
	var data = {};
	$scope.tickets = [];
	$scope.users = [];
	$scope.search = {
		designation: '',
		title: '',
		state: '',
		priority: '',
		user: {
			login: ''
		},
		responsable: {
			login: ''
		},
		client: {
			designation: ''
		},
		workplace: {
			designation: ''
		}
	};
	$scope.searchPeriod = {
		created_at: '',
		finished_at: ''
	};
	
	/**
	 * Filters
	 */
	
	Filter.enable($scope, 'search');
	Filter.enable($scope, 'searchPeriod', true);

	/**
	 * STATS
	 */
	$scope.stats = {
		'opened': 0,
		'finished': 0,
		'forwarded': 0
	}
	
	/**
	 * Utils
	 */
	$scope.initializeTicket = function() {
		$scope.$broadcast('initTicket');
	};
	
    var filterTrashed = function(collection) {
        return _.filter(collection, {isTrashed: false});
    }; 
	
	$scope.countBy = function(collection, state) {
		return (state != null) ? _.filter(collection, {state: state}).length : collection.length;
	};
	
	$scope.timestampToDate = function(timestamp) {
		return new Date(timestamp);	
	};
	
	$scope.getWorkplaces = function() {
		return _.isEmpty($scope.search.client.designation) ? $scope.workplaces : _.find($scope.clients, {designation: $scope.search.client.designation}).workplaces;
	};

	/**
	 * PAGINATION
	 */
	$scope.pagination = {}

	var formatPagination = function(queryResult) {
		if (queryResult) {
			$scope.pagination = {
				current: queryResult.current_page,
				last: queryResult.last_page == queryResult.current_page ? null : queryResult.last_page,
				previous: queryResult.current_page == 1 ? null : (queryResult.current_page - 1),
				next: queryResult.last_page == queryResult.current_page  ? null : (queryResult.current_page + 1)
		  	}
	
		  	$scope.pagination.range = formatPaginationRange()
		} else {
		  	$scope.pagination = {
				current: null,
				last: null,
				previous: null,
				next: null,
				range: []
		  	}
		}    
	}

	/**
	 * Construct range [1,2,3]
	 * @param pagination 
	 */
  	var formatPaginationRange = function() {
		var range = [];
		let limit = $scope.pagination.last ? $scope.pagination.last : $scope.pagination.current
		for(var i = -2 ; i < 3; i ++) {
			var pageNumber = $scope.pagination.current + i
			if (pageNumber > 0 && pageNumber <= limit) {
				range.push(pageNumber)
			}
		}
		return range;
	}

	/**
	 * Changement de page
	 */
	$scope.goToPage = function(page) {
		$scope.getTickets(page)
	}

	/**
	 * Get asynchronous data
	 */
	// Récupération des rapports
	$scope.getTickets = function(page) {
		Loading.register('tickets', Ticket.paginate(page, $scope.search, $scope.searchPeriod), function (response) {
			$scope.tickets = response.data;
			formatPagination(response);
		});

		Ticket.counts($scope.search, $scope.searchPeriod).success(function(data) { $scope.stats = data });
	};

	$scope.getTickets(1);

	$scope.getClients = function(val) {
		return Client.getDesignations({designation: val}).then(function(response){
			return response.data
		});
	};

	User.get().then(function(response){
		$scope.users = response.data;
	});

	$scope.getWorkplaces = function(val) {
		return Workplace.getDesignations({designation: val, client: $scope.search.client.designation}).then(function(response){
			return response.data
		});
	};

	// Réinitialisation des filtres
	$scope.resetFilters = function() {
		$scope.filter.reset($scope.search); 
		$scope.filter.reset($scope.searchPeriod);

		setTimeout(function(){
			$scope.getTickets(1);
		}, 1000);
	}

	/**
	 * Export
	 */
	$scope.export = function (format) {
		Ticket.export(format, $scope.search, $scope.searchPeriod).success(function(data, status, headers){
			headers = headers();
 
			var filename = 'export_tickets_' + moment().format('DD/MM/YYYY');
			var contentType = headers['content-type'];
	 
			var linkElement = document.createElement('a');
			try {
				var blob = new Blob([data], { type: contentType });
				var url = window.URL.createObjectURL(blob);
	 
				linkElement.setAttribute('href', url);
				linkElement.setAttribute("download", filename);
	 
				var clickEvent = new MouseEvent("click", {
					"view": window,
					"bubbles": true,
					"cancelable": false
				});
				linkElement.dispatchEvent(clickEvent);
			} catch (ex) {
				console.log(ex);
			}
		});
	};
});