angular.module('maintenanceTypesController', []).controller('maintenanceTypesController', function($filter, $scope, $timeout, $translate, MaintenanceType, EquipmentType, Document, Form, Alert, DirtyModel, uuid4, Draft, Loading) {

    var currentNode;
    
    $scope.displayPanel = false;
    $scope.displayDetails = false;
    $scope.isImportActive = false;
    $scope.created = {};
    $scope.filter = $filter('uiTreeFilter');

    $scope.options = {
        accept: function(sourceNodeScope, destNodesScope, destIndex) {
            return true;
        }
    };

    /**
     * Utils
     */

    var getStandardFormId = function() {
        return !_.isEmpty($scope.forms) ? _.first(_.sortBy($scope.forms, function(n) { return n.created_at }))._id : null;
    };

    var reGenerateNestedIds = function(items) {
        // re-generate all nested _id to prevent collision when you import 2 or more times the same tree
        items.forEach(function (item) {
            item._id = uuid4.generate();
            if (item.items.length) {
                return reGenerateNestedIds(item.items);
            }
        });
        return items;
    };

    /**
     * Drafts
     */

    $scope.recoverDraft = function() {
        Draft.recover($scope);
        $scope.displayPanel = true;
        $scope.displayDetails = false;
    };

    /**
     * Initial data
     */

	Loading.register('activities', MaintenanceType.get(), function (data) { 
        $scope.selected = _.first(data);
        $scope.data = data;
        DirtyModel.watch($scope, 'selected');
    });
    
    Form.get()
        .success(function(data) { $scope.forms = data; })
        .error(function(data) { console.log(data); });        

    /**
     * Watch new model creation
     */

    DirtyModel.watch($scope, 'created');

    /**
     * Selection
     */
    
    $scope.importTree = function() {
        $scope.isImportActive = true;
    };

    $scope.select = function(id) {
        $scope.selected = _.find($scope.data, { id: id });
        $timeout(setNanoScroller, 1000);
        DirtyModel.reset($scope, 'selected');
    };

    $scope.isTreeSelected = function(treeId) {
        return $scope.equipment_type_tree != null ? ($scope.equipment_type_tree._id === treeId) : false;
    };

    $scope.isSelected = function(id) {
        return $scope.selected.id === id;
    };

    $scope.selectTree = function(treeId) {

        if ($scope.isImportActive) {
            var tree = _.find($scope.selected.equipment_type_trees, { _id: treeId });
            $scope.item.items = reGenerateNestedIds(angular.copy(tree.items));
            $scope.isImportActive = false;
        }
        else {

            $scope.displayPanel = true;
            $scope.displayDetails = false;
            $scope.equipment_type_tree = _.find($scope.selected.equipment_type_trees, { _id: treeId });

            // get the draft for this tree and reset watcher
            Draft.get('equipment_type_tree', treeId)
                .success(function(data) {
                    $scope.draft = _.isEmpty(data) ? null : data; 
                    $scope.displayDraftPanel = !!$scope.draft;
                    DirtyModel.reset($scope, 'equipment_type_tree');
                })
                .error(function(data) { console.log(data); });
        }
    };

    $scope.isNodeSelected = function(node) {
        return node === currentNode;
    };

    /**
     * CRUD operations
     */

    $scope.createTree = function(ref_id) {
        $scope.displayPanel = true;
        $scope.equipment_type_tree = {
            _id: uuid4.generate(), // temp _id
            isNew: true,
            title: $translate.instant('newTree'),
            maintenance_type_id: ref_id,
            items: [
                {
                    _id: uuid4.generate(),
                    title: $translate.instant('rootNode'),
                    items: [],
                    documents: [],
                    form_id: getStandardFormId()
                }
            ]
        };

        // get the draft for this tree and reset watcher
        Draft.get('equipment_type_tree')
            .success(function(data) {
                $scope.draft = _.isEmpty(data) ? null : data; 
                $scope.displayDraftPanel = !!$scope.draft;
                DirtyModel.reset($scope, 'equipment_type_tree');
            })
            .error(function(data) { console.log(data); });

        $scope.selected.equipment_type_trees.push($scope.equipment_type_tree);
    };

    $scope.saveTree = function() {
        $scope.loadingGlobal = true; 
        $scope.errors = null;
        
        ( $scope.equipment_type_tree.isNew ? EquipmentType.create($scope.equipment_type_tree) : EquipmentType.update($scope.equipment_type_tree._id, $scope.equipment_type_tree) )
            .success(function (data) {
                $scope.loadingGlobal = false;
                $scope.equipment_type_tree._id = data._id;
                $scope.equipment_type_tree.isNew = false;
                // re-sort trees
                $scope.selected.equipment_type_trees.sort(function (a, b) { 
                    return a.title === b.title ? 0 : a.title < b.title ? -1 : 1; 
                });
                DirtyModel.reset($scope, 'equipment_type_tree');
                $('.alert.alert-success').fadeIn().delay(2000).fadeOut();
            })
            .error(function(data) {
                $scope.loadingGlobal = false;
                $scope.errors = data;
                $('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
            });
    };

    $scope.create = function() {
        $scope.loadingGlobal = true; 
        $scope.errors = null;

        MaintenanceType.create($scope.created)
            .success(function(data) {
                $scope.loadingGlobal = false;
                $scope.created = {};
                $scope.data.push(data);
                $scope.data = _.sortBy($scope.data, 'designation');
                $scope.selected = data;
                DirtyModel.reset($scope, 'selected');
                DirtyModel.reset($scope, 'created');
                $('.alert.alert-success').fadeIn().delay(2000).fadeOut();
                $('#addMaintenanceType').modal('hide');
            })
            .error(function(data) {
                $scope.loadingGlobal = false;
                $scope.errors = data;
                $('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
            });
    };

    $scope.update = function() {
        $scope.loadingGlobal = true; 
        $scope.errors = null;

        MaintenanceType.update($scope.selected.id, $scope.selected)
            .success(function(data) { 
                $scope.loadingGlobal = false;
                DirtyModel.reset($scope, 'selected');
                $('.alert.alert-success').fadeIn().delay(2000).fadeOut();
                $('#editMaintenanceType').modal('hide');
            })
            .error(function(data) {
                $scope.loadingGlobal = false;
                $scope.errors = data;
                $('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
            });
    };

    $scope.destroy = function () {
        Alert.confirm(function() { return MaintenanceType.destroy($scope.selected.id) }, function() {
            _.remove($scope.data, function (item) { return item.id === $scope.selected.id; });
            $scope.selected = _.first($scope.data);
            DirtyModel.reset($scope, 'selected');
            $scope.displayDetails = false;
        });
    };

    /**
     * Document listeners
     */
    
    $scope.$on('documentAdded', function (e, document) {
        $scope.item.documents.push(document);
        $('#addDocument' + e.targetScope.$id).modal('hide');
    });
    
    $scope.$on('documentDestroyed', function (e, document) {
        _.remove($scope.item.documents, function (doc) { return doc.id === document.id; });        
    });
    
    /**
     * Forms
     */
     
    $scope.selectForm = function(formId) {
        $scope.item.form_id = formId;
        $('#choiceForm').modal('hide');
    };

    $scope.removeForm = function() {
        $scope.item.form_id = null;
    };

    $scope.getForm = function() {
        if ($scope.item != null) {
            return _.find($scope.forms, { _id: $scope.item.form_id });
        }
        return null;
    };

    /**
     * Tree
     */

    $scope.remove = function() {
        currentNode.remove();
        currentNode = null;
        $scope.item = null;
        $scope.displayDetails = false;
    };

    $scope.toggle = function(scope) {
        scope.toggle();
    };

    $scope.selectItem = function(scope) {
        if ($scope.displayDetails && !$scope.detailsForm.$valid) {
            return;
        }
        currentNode = scope;
        $scope.item = scope.$modelValue;
        $scope.displayDetails = true;
        $scope.detailsForm.$valid = true; // bug fix : Form is valid by default, it won't display save button without that line
    };

    $scope.newSubItem = function() {
        $scope.item.items.push({
            _id: uuid4.generate(),
            title: $scope.item.title + '.' + ($scope.item.items.length + 1),
            items: [],
            documents: [],
            form_id: getStandardFormId()
        });
    };

    // automatically select a newly created node
    $scope.nodeCreated = function(scope) {
        $timeout(function() { $scope.selectItem(scope); });
    };
});
