angular.module('remarksCreateController', []).controller('remarksCreateController', function($scope, Remark, Route) {

	/**
	 * Initial data
	 */
    
    $scope.remark = {
        documents: []
    };

    var currentId = Route.getAction()[1];

    /**
     * Document listeners
     */
    
    $scope.$on('documentAdded', function (e, document) {
        $scope.remark.documents.push(document);
        $('#addDocument' + e.targetScope.$id).modal('hide');
		setTimeout(function(){ $('body').addClass('modal-open') }, 1000);
    });
    
    $scope.$on('documentDestroyed', function (e, document) {
        _.remove($scope.remark.documents, function (doc) { return doc.id === document.id; });        
    });
    
	/**
	 * Model actions
	 */
    
    $scope.create = function() { 
        $scope.loadingGlobal = true;
        Remark.create(_.assign($scope.remark, { equipment_id: $scope.$parent.item._id, report_id: currentId }))
            .success(function(data) {
                $scope.$parent.remarks.push(data);
                $scope.$parent.remark = data;
                $('.alert.alert-success').fadeIn().delay(2000).fadeOut();
                $('#addRemark').modal('hide');
            })
            .error(function(data) {
                console.log(data); 
                $('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
            })
			.finally(function() { $scope.loadingGlobal = false; });
    };
});