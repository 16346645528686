angular.module('remarksEditController', []).controller('remarksEditController', function($scope, $timeout, Remark) {

    /**
     * Document listeners
     */
    
    $scope.$on('documentAdded', function (e, document) {
        if (!_.isArray($scope.remark.documents)) {
            $scope.remark.documents = [];
        }
        $scope.remark.documents.push(document);
        $('#addDocument' + e.targetScope.$id).modal('hide');
    });
    
    $scope.$on('documentDestroyed', function (e, document) {
        _.remove($scope.remark.documents, function (doc) { return doc.id === document.id; });  
        $timeout($scope.update);       
    });
    
	/**
	 * Model actions
	 */
	
    $scope.update = function() { 
        $scope.loadingGlobal = true;
        Remark.update($scope.remark._id, $scope.remark)
            .success(function(data) {
                $('.alert.alert-success').fadeIn().delay(2000).fadeOut();
                $('#editRemark' + $scope.remark._id).modal('hide');
            })
            .error(function(data) {
                console.log(data); 
                $('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
            })
			.finally(function() { $scope.loadingGlobal = false; });
    };
});