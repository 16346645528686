angular.module('settingsController', []).controller('settingsController', function($scope) {
    
    $scope.pdfLogo = undefined;
    
    /**
     * Document listeners
     */
    
    $scope.$on('documentAdded', function (e, document) {
       $scope.pdfLogo = document;
    });
    
    $scope.$on('documentDestroyed', function (e, document) {
       $scope.pdfLogo = null;
    });
});