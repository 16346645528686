angular.module('formsIndexController', []).controller('formsIndexController', function($scope, $builder, Form, Alert, Loading, Equipment, Filter) {
    
	/**
	 * Initial data
	 */
	
    $scope.forms = [];
	$scope.references = {};
	$scope.ITEMS_PER_PAGE = 10;
	$scope.search = {
		title: '',
	};

	Filter.enable($scope, 'search');

	Form.loadComponents();
	
	/**
	 * Utils
	 */

	$scope.getRevisionNumber = function(form) {
		return _.size(_.filter($scope.forms, function(n) { return n.title === form.title && n.created_at <= form.created_at }));
	};
	
	$scope.buildForm = function(form) {
        $builder.forms['default'].length = 0; // empty formbuilder
        form.fields.forEach(function (field) { $builder.addFormObject('default', field) }); // add all fields
    };

	var getFormFromEquipmentId = function(equipment_id, items) {
		for (var i = 0; i < items.length; i++) {
			var item = items[i];
			if (item._id === equipment_id) {
				return _.find($scope.forms, { _id: item.form_id });
			}
            if (item.items.length) {
                return getFormFromEquipmentId(equipment_id, item.items);
            }
        }
	};

	var updateFormId = function(equipment_id, form_id, items) {
        items.forEach(function (item) {
			if (item._id === equipment_id) {
				item.form_id = form_id;
			}
            if (item.items.length) {
                return updateFormId(equipment_id, form_id, item.items);
            }
        });
		return items;
    };

	var getReferences = function(form) {
		var entries = [];
		form.revisions.forEach(function(revision) {
			revision.operations.forEach(function(operation) {
				if (operation.report != null) {
					Equipment.get(operation.report.equipment_tree._id).success(function(data) { // get equipment_tree revisions
						var entry = {};
						entry.title = operation.report.equipment_tree.title;
						entry.equipment_title = operation.equipment_title;
						entry.equipment_tree = data.revisions[0];
						entry.equipment_id = operation.equipment_id;
						entry.revision = revision;
						entry._id = entry.equipment_tree._id;
						entry.form = getFormFromEquipmentId(entry.equipment_id, entry.equipment_tree.items) || getFormFromEquipmentId(entry.equipment_id, operation.report.equipment_tree.items);
						entries.push(entry);
						entries.sort(function(a, b) { return a.equipment_tree.created_at <= b.equipment_tree.created_at });
					});
				}
			});
		});
		return entries;
	};

	/**
	 * Get asynchronous data
	 */
	
	Loading.register('forms', Form.get(), function (data) { $scope.forms = data });
    
	/**
	 * Model actions
	 */

	$scope.getReferences = function(id) {
		Loading.register('refs' + id, Form.getEquipmentsFromForm(id), function(data) {
			//$scope.references[id] = getReferences(data); // cache references
		}); 
	};

    $scope.destroy = function(id) {
        Alert.confirm(
            function() { return Form.destroy(id) }, 
            function() { _.remove($scope.forms, function (model) { return model._id === id; })}
        );
    };

	$scope.updateRef = function(ref, form) {
		updateFormId(ref.equipment_id, form._id, ref.equipment_tree.items);
		Loading.register('ref' + ref._id, Equipment.create(ref.equipment_tree), function (data) {
			ref.equipment_tree = data;
			ref.form = getFormFromEquipmentId(ref.equipment_id, ref.equipment_tree.items);
		});
	};
});