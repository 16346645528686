angular.module('dirtyModelService', []).factory('DirtyModel', function($timeout, $translate, Draft) {

    var $$watchers = {};
    var DRAFT_WHITELIST = ['equipment_tree', 'equipment_type_tree', 'form', 'input_operation']; // all models that can be converted to draft

    return {
        reset: function(scope, model) {
            this.unwatch(model);
            this.watch(scope, model);
            // destroy the draft if there is one
            if (scope.draft != null) {
                Draft.destroy(scope.draft._id);
            }
        },
        unwatch: function(model) {
            if (_.has($$watchers, model)) {
                $$watchers[model].watcher();
                delete $$watchers[model];
            }
        },
        watch: function(scope, model) {
            $timeout(function() {
                $$watchers[model] = {
                    initialValue: _.clone(scope[model], true),
                    watcher: scope.$watch(model, function (newVal, oldVal) {

                        // use angular.toJson to strip $$hashKey
                        scope.isDirty = !_.isEqual(angular.toJson(newVal), angular.toJson($$watchers[model].initialValue)); 
                        
                        // create or update the draft if the model is dirty, can be converted to draft and a change is detected
                        if (scope.isDirty && !_.isEqual(angular.toJson(newVal), angular.toJson(oldVal)) && _.includes(DRAFT_WHITELIST, model)) {

                            ( (scope.draft != null) ? Draft.update(scope.draft._id, newVal) : Draft.create({ model: model, id: scope[model]._id, data: newVal}) )
                                .success(function (data) { scope.draft = data; })
                                .error(function (data) { console.log(data); });
                        }

                        // onbeforeunload message
                        window.onbeforeunload = scope.isDirty ? function (e) { return $translate.instant('onbeforeunload'); } : null;

                    }, true)
                };
            });
        }
    };
});