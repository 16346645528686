angular.module('userSettingService', []).factory('UserSetting', function($http, $q, CacheFactory) {
    
    var data, cache; // private var
                
    return {
        
        initialize: function() {
            
            // initialize cache
            if (!CacheFactory.get('tourCache')) {
                CacheFactory.createCache('tourCache', { storageMode: 'localStorage' });
            }
                
            cache = CacheFactory.get('tourCache');
            data = cache.get('user_settings');
            
            // get data
            if (!data) {
                return $http.get('user_settings').success(function(result) {
                    data = result;
                    cache.put('user_settings', result);
                });
            }
            else {
                return (function() {
                    var deferred = $q.defer();
                    deferred.resolve();
                    return deferred.promise;
                })();
            }
        },
        
        _persist: function(method, key, rData) {
            // persist in cache
            cache.put('user_settings', data);
            // persist remotely
            $http({
                method: 'POST',
                url: 'user_settings/' + key,
                offline: true,
                data: _.assign({ _method: method }, rData)
            });
        },
        
        getItem: function(key) {
            return data[key];
        },
        
        setItem: function(key, value) {
            data[key] = value;
            this._persist('PATCH', key, {value: value});
        },
        
        removeItem: function(key) {
            delete data[key];
            this._persist('DELETE', key);
        }
    };
    
});