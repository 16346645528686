angular.module('clientService', []).factory('Client', function($http) {

    /**
	 * Construction chaine de caractère pour filtres
	 * @param filters 
	 */
  	var formatFilters = function(filters) {
        var string = ''
        if (filters) {
            if (filters.designation) { string += '&designation=' + filters.designation }
        }
        return string;
    }
    
    return {
    
        get: function(id) {
            return $http({
                method: 'GET',
                url: 'clients' + (id != null ? '/' + id : ''),
                offline: true
            });
        },
        create: function(data) {
            return $http({
                method: 'POST',
                url: 'clients',
                offline: true,
                data: data
            });
        },
       	update: function(id, data) {
            return $http({
                method: 'POST',
                url: 'clients/' + id,
                offline: true,
                data: _.assign({ _method: 'PATCH' }, data)
            });
        },
        destroy: function(clientId) {
            return $http({
                method: 'POST',
                url: 'clients/' + clientId,
                offline: true,
                data: {_method: 'DELETE'},
            });
        },
        getDesignations: function(filters) {
            return $http({
                method: 'GET',
                url: 'api/clients/get_designations?filters' + formatFilters(filters),
                offline: true
            });
        }
    };
});
