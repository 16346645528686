angular.module('equipmentController', []).controller('equipmentController', function($filter, $scope, $timeout, $translate, Equipment, MaintenanceType, Form, Document, Alert, DirtyModel, Draft, Route, uuid4) {

    var currentNode;

    $scope.loadingGlobal = true;
    $scope.displayDetails = false;
    $scope.filter = $filter('uiTreeFilter');

    $scope.options = {
        accept: function(sourceNodeScope, destNodesScope, destIndex) {
            return true;
        }
    };

    /**
     * Check for controller action and id
     */

    var action = Route.getAction()[0];
    var currentId = Route.getAction()[1];
    var defaultFormId = null;

    /**
     * Utils
     */

    var getStandardFormId = function() {
        return defaultFormId;
    };

    var reGenerateNestedIds = function(items) {
        // re-generate all nested _id to prevent collision when you import 2 or more times the same tree
        items.forEach(function (item) {
            item._id = uuid4.generate();
            if (item.items.length) {
                return reGenerateNestedIds(item.items);
            }
        });
        return items;
    };

    /**
     * Drafts
     */

    $scope.recoverDraft = function() {
        Draft.recover($scope);
        $scope.displayDetails = false;
    };

    Draft.get('equipment_tree', currentId)
        .success(function(data) {
            $scope.draft = _.isEmpty(data) ? null : data; 
            $scope.displayDraftPanel = !!$scope.draft;
        })
        .error(function(data) { console.log(data); });

    /**
     * Initial data
     */

    MaintenanceType.get()
        .success(function(data) { 
            $scope.selected = _.first(data);
            $scope.data = data;
            $scope.loadingGlobal = false; 
        })
        .error(function(data) { console.log(data); });


    // Get default form id
    Form.getDefault()
        .success(function(data) { 
            defaultFormId = data.form;
        })
        .error(function(data) { console.log(data); }); 

    // 
    Form.get()
        .success(function(data) { 
            $scope.forms = data; 
            if (action === 'CREATE') {
                $scope.equipment_tree = {
                    title: '',
                    items: [
                        {
                            _id: uuid4.generate(),
                            title: $translate.instant('rootNode'),
                            items: [],
                            documents: [],
                            form_id: getStandardFormId()
                        }
                    ]
                };
                DirtyModel.watch($scope, 'equipment_tree');
            }
        })
        .error(function(data) { console.log(data); });   

    if (action === 'EDIT') {
        Equipment.get(currentId)
            .success(function (data) {
                $scope.equipment_tree = data;
                $scope.displayDetails = false;
                DirtyModel.watch($scope, 'equipment_tree');
                // refresh nanoscroller when the tree is displayed
                $timeout(setNanoScroller, 1000);
            });
    }

    /**
     * Selection
     */

    $scope.select = function(id) {
        $scope.selected = _.find($scope.data, { id: id });
        $timeout(setNanoScroller, 1000);
    };

    $scope.isSelected = function(id) {
        return $scope.selected.id === id;
    };

    $scope.isTreeSelected = function(treeId) {
        return $scope.selectedTree != null ? ($scope.selectedTree._id === treeId) : false;
    };

    $scope.selectTree = function(treeId) {
        $scope.selectedTree = _.find($scope.selected.equipment_type_trees, { _id: treeId });
    };

    $scope.importTree = function() {
        $scope.item.items = reGenerateNestedIds(angular.copy($scope.selectedTree.items));
    };

    $scope.isNodeSelected = function(node) {
        return node === currentNode;
    };
    
    /**
     * CRUD operations
     */

    $scope.saveTree = function() {
        $scope.loadingGlobal = true;

        Equipment.create($scope.equipment_tree)
            .success(function (data) {
                $scope.loadingGlobal = false;
                DirtyModel.reset($scope, 'equipment_tree');
                Route.setNewRouteId($scope.equipment_tree, data);
                $('.alert.alert-success').fadeIn().delay(2000).fadeOut();
            })
            .error(function(data) {
                console.log(data); 
                $scope.loadingGlobal = false;
                $('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
            });
    };

    /**
     * Document listeners
     */
    
    $scope.$on('documentAdded', function (e, document) {
        $scope.item.documents.push(document);
        $('#addDocument' + e.targetScope.$id).modal('hide');
    });
    
    $scope.$on('documentDestroyed', function (e, document) {
        _.remove($scope.item.documents, function (doc) { return doc.id === document.id; });        
    });

    /**
     * Forms
     */
     
    $scope.selectForm = function(formId) {
        $scope.item.form_id = formId;
        $('#choiceForm').modal('hide');
    };

    $scope.removeForm = function() {
        $scope.item.form_id = null;
    };

    $scope.getForm = function() {
        if ($scope.item != null) {
            return _.find($scope.forms, { _id: $scope.item.form_id });
        }
        return null;
    };

    /**
     * Tree
     */

    $scope.remove = function() {
        currentNode.remove();
        currentNode = null;
        $scope.item = null;
        $scope.displayDetails = false;
    };

    $scope.toggle = function(scope) {
        scope.toggle();
    };

    $scope.selectItem = function(scope) {
        if ($scope.displayDetails && !$scope.detailsForm.$valid) {
            return;
        }
        currentNode = scope;
        $scope.item = scope.$modelValue;
        $scope.displayDetails = true;
        $scope.detailsForm.$valid = true; // bug fix : Form is valid by default, it won't display save button without that line
    };

    $scope.newSubItem = function() {
        $scope.item.items.push({
            _id: uuid4.generate(),
            title: $scope.item.title + '.' + ($scope.item.items.length + 1),
            items: [],
            documents: [],
            form_id: getStandardFormId()
        });
    };

    // automatically select a newly created node
    $scope.nodeCreated = function(scope) {
        $timeout(function() { $scope.selectItem(scope); });
    };
});
