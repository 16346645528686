angular.module('userService', []).factory('User', function($http) {

    return {

        get: function() {
            return $http({
                method: 'GET',
                url: 'admin/users',
                offline: true
            });
        },
        destroy: function(userId) {
            return $http({
                method: 'POST',
                url: 'admin/users/' + userId,
                offline: true,
                data: {_method: 'DELETE'},
            });
        },
        readTimeline: function() {
            return $http({
                method: 'POST',
                offline: true,
                url: 'users/read_timeline'
            });
        },
        stats: function() {
            return $http({
                method: 'GET',
                url: 'users/stats/graph',
                offline: true
            });    
        }
    };
});
