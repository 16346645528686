angular.module('remarkService', []).factory('Remark', function($http) {

    /**
	 * Construction chaine de caractère pour filtres
	 * @param filters 
	 */
  	var formatFilters = function(filters, filtersPeriod) {
        var string = ''
        if (filters.designation) { string += '&number=' + filters.designation }
        if (filters.state) { string += '&state=' + filters.state }
        if (filters.priority) { string += '&priority=' + filters.priority }

        if (filtersPeriod.created_at.length != 0) {
            string += '&start_at=' + moment(filtersPeriod.created_at, 'dddd LL').format('YYYY/MM/DD')
        }
        if (filtersPeriod.finished_at.length != 0) {
            string += '&end_at=' + moment(filtersPeriod.finished_at, 'dddd LL').format('YYYY/MM/DD')
        }

		return string;
    }

    return {

        get: function(reportId) {
            return $http({
                method: 'GET',
                url: 'remarks' + (reportId != null ? '/' + reportId : ''),
                offline: true
            });
        }, 
        paginate: function(page, filters, filtersPeriod) {
            var url = 'remarks?page=' + page
            url += formatFilters(filters, filtersPeriod)

            return $http({
                method: 'GET',
                url: url,
                offline: true
            });
        },
        getLast: function(limit) {
            return $http({
                method: 'GET',
                url: 'api/remarks/get_last/' + limit,
                offline: true
            });
        },
        create: function(data) {
            return $http({
                method: 'POST',
                url: 'remarks',
                offline: true,
                data: data
            });
        },
       	update: function(id, data) {
            return $http({
                method: 'POST',
                url: 'remarks/' + id,
                offline: true,
                data: _.assign({ _method: 'PATCH' }, data)
            });
        },
        destroy: function(id) {
            return $http({
                method: 'POST',
                url: 'remarks/' + id,
                offline: true,
                data: {_method: 'DELETE'}
            });
        },
        stats: function() {
            return $http({
                method: 'GET',
                url: 'api/remarks/stats/graph',
                offline: true
            });    
        },
        counts: function(filters, filtersPeriod) {
            var url = 'api/remarks/stats/count?filters'
            url += formatFilters(filters, filtersPeriod)

            return $http({
                method: 'GET',
                url: url,
                offline: true
            });    
        },
    };
});
