angular.module('ticketsShowController', []).controller('ticketsShowController', function($scope, $timeout, $window, $location, Ticket, Route, Alert, Comment, Contact) {

	/**
	 * Utils
	 */
	$scope.initComment = function (){
		$scope.comment = angular.copy({
			documents : []
		});

		if ($scope.createCommentForm) {
			$scope.createCommentForm.$setUntouched();
		}
	};
	/**
	 * Initial data
	 */
	$scope.ticket = {};
	$scope.contacts = [];
	$scope.initComment();

    var path = $window.location.href;
	var notifyAction = path.search('notify') !== -1;
	var temp = path.substring(path.lastIndexOf('/') + 1);
	var currentId = notifyAction ? temp.substring(0, temp.length - 7) : temp;

	/**
	 * Get asynchronous data
	 */
	$scope.loadingGlobal = true;

	// Retrieve ticket informations
    Ticket.get(currentId)
        .success(function (data) { 
            $scope.ticket = data;
			if (notifyAction) {
				$scope.openNotifyForm();
			}
        })
        .finally(function() { $scope.loadingGlobal = false; });

	// Retrieve ticket timeline
	Ticket.timeline(currentId)
		.success(function (data) {
			$scope.timeline = data;
		});

	$scope.timestampToDate = function(timestamp) {
		return new Date(timestamp);
	};

	$scope.edit = function (ticket) {
		$scope.$broadcast('editTicket', ticket);
	};

	/**
	 * Document listeners
	 */
	$scope.$on('documentAdded', function (e, document) {
		$scope.comment.documents.push(document);
		$('#addDocument' + e.targetScope.$id).modal('hide');
	});

	$scope.$on('documentDestroyed', function (e, document) {		
		_.remove($scope.comment.documents, function (doc) { 
			Comment.update($scope.comment._id, $scope.comment).success(function (comment){
				$scope.timeline[0] = angular.copy(comment);
			})
			return doc.id === document.id; 
		});
	});

	$scope.editComment = function (comment) {
		$scope.comment = angular.copy(comment);
	};

	/**
	 * Model actions
	 */
	$scope.createComment = function() {
		$scope.loadingGlobal = true;
		Comment.create(_.assign($scope.comment, { ticket_id: currentId }))
			.success(function(comment) {
				$scope.timeline.splice(0, 0, comment);
				if ('is_deletable' in $scope.timeline[1]) {
					$scope.timeline[1].is_deletable = false;
				}
				$scope.initComment();
				$scope.createCommentForm.$setUntouched();
				$('.alert.alert-success').fadeIn().delay(2000).fadeOut();
			})
			.error(function(data) {
				console.log(data);
				$('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
			})
			.finally(function() { $scope.loadingGlobal = false; });
	};

	$scope.updateComment = function() {
		$scope.loadingGlobal = true;
		Comment.update($scope.comment._id, $scope.comment)
			.success(function(comment) {
				$scope.timeline[0] = angular.copy(comment);
				$scope.initComment();
				$('.alert.alert-success').fadeIn().delay(2000).fadeOut();
			})
			.error(function(data) {
				console.log(data);
				$('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
			})
			.finally(function() { $scope.loadingGlobal = false; });
	};

	$scope.destroyComment = function(id) {
		Alert.confirm(
			function() { return Comment.destroy(id) },
			function() { $window.location = './tickets/' + currentId }
		);
	};


	// Destroy ticket
    $scope.destroy = function(id) {
        Alert.confirm(
            function() { return Ticket.destroy(id) },
            function() { $window.location = './tickets/' }
        );
    };

	$scope.openNotifyForm = function () {
		Contact.get($scope.ticket.client_id)
			.success(function (data){
				$scope.contacts = data;
				$('#notify').modal('show');
				if (data.length === 0) {
					$scope.$broadcast('noContactsFound');
				}
			})
			.error(function (data) { console.log(data); });
	};

	$scope.notify = function() {
		$scope.loadingGlobal = true;
		var data = {};
		_.assign(data, {
			ticket_id: currentId,
			contacts: $scope.contacts
		});

		$('#notify').modal('hide');

		Ticket.notify(data)
			.success(function(data) {
				$scope.ticket = angular.copy(data);
				$window.location = './tickets/' + currentId;
			})
			.error(function(data) {
				$('#notify').modal('show');
				console.log(data);
				$('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
			})
			.finally(function() { $scope.loadingGlobal = false; });
	};
});