angular.module('usersController', []).controller('usersController', function($scope, User, Client, Alert) {

	/**
	 * Initial data
	 */

    var hiddenUsers = [];

	/**
	 * Utils
	 */
	
    var filterTrashed = function(collection) {
        return _.filter(collection, {isTrashed: false});
    }; 

	/**
	 * Get asynchronous data
	 */

    User.get().success(function (data) { $scope.users = filterTrashed(data); });
    Client.get().success(function (data) { $scope.clients = filterTrashed(data); });
    
    $scope.displayUser = function(userId) {
        return !_.contains(hiddenUsers, userId);
    };

    $scope.destroyUser = function(userId) {        
        Alert.confirm(function() { return User.destroy(userId) }, function() { hiddenUsers.push(userId) });
    };
});