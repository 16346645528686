angular.module('mainController', []).controller('mainController', function($scope, $translate, $timeout, User, UserSetting, connectionStatus, Tour, Entrust) {

    $scope.date = new Date();
    $scope.showTimelineCounter = true;
    $scope.activeTab = '';

    setInterval(function() {
        $scope.date = new Date();
        $scope.$apply();
    }, 1000);

    $scope.readTimeline = function() {
    	User.readTimeline()
    		.success(function (data) { $scope.showTimelineCounter = false; })
    		.error(function (data) { console.log(data); });
    };

    $scope.isOnline = function() {
        return connectionStatus.isOnline();
    };

    UserSetting.initialize();

    /**
     * Utils
     */
    $scope.savePagination = function (model, per_page) {
        UserSetting.setItem('pagination_' + model, per_page);
    };

    $scope.getPagination = function (model) {
        return UserSetting.getItem('pagination_' + model) || 10;
    };
    
    $scope.setTab = function (tabName) {
        $scope.activeTab = tabName;
    };

    $scope.isTabActive = function (tabName) {
        return $scope.activeTab === tabName;
    };

    $scope.$on('tab', function (evnt, data) {
        $scope.activeTab = data;
    });

    $scope.hideSubmitButton = function($event){
        angular.element($event.currentTarget).hide();
    }

    /**
     * Bootstrap-tour
     */
    
    var tourHelper = {
        
        /**
         * Only display the next button if the element is filled
         */
        showNextOnInput: function(selector) {
         
            var $next = $('.popover[class*="tour-"] .popover-navigation *[data-role="next"]');
            $next.hide();
            
            $(selector).on('input', function() {
                $(this).val().length > 0 ? $next.show() : $next.hide();
            });
        },
        
        /**
         * Bug : when using reflex option and set next to -1, step is not increased
         * Fix : manually disable next button 
         */
        removeNextButton: function() {
            $('.popover[class*="tour-"] .popover-navigation *[data-role="next"]').remove();  
        },
        
        /**
         * Bug : if you refresh the page and a field of a form is not filled, try to submit the form
         * and you get an error, causing a redirection infinite loop
         * Fix : manually fill the field
         */ 
        forceFieldToBeFilled: function(selector, step) {

            if ($(selector).val().trim() === '') {
                $(selector).val($translate.instant('tour.step' + step + '.extra'));
            }
        },
        
        /**
         * Bug : if you refresh the page and a panel is hidden by angular, you can't continue the tour
         * Fix : go back to an anterior step
         */
        goBackIfVisible: function(tour, selector, step) {
            $timeout(function() {
                if ($(selector).is(':visible')) {
                    tour.goTo(step);
                } 
            }, 1000);
        },
        
        /**
         * Bug : if you refresh the page and a modal is not opened, you can't continue the tour
         * Fix : go back to an anterior step
         */
        goBackIfHidden: function(tour, selector, step) {
            $timeout(function() {
                if ($(selector).is(':hidden')) {
                    tour.goTo(step);
                } 
            }, 1000);
        },
        
        /**
         * Bug : an element with fixed position isn't properly highlighted
         * Fix : move the element after the tour-backdrop and tour-step-background
         */
        highlightFixedElement: function(selector) {
            $(selector).after($('.tour-step-background'));
            $(selector).after($('.tour-backdrop'));
        },
    };
    
    var welcomeSteps = [
        {
            element: '#tour-element-0',
            title: $translate.instant('tour.step0.title'),
            content: $translate.instant('tour.step0.content'),
            placement: 'top',
            path: '/'
        },
        {
            element: '#tour-element-1',
            title: $translate.instant('tour.step1.title'),
            content: $translate.instant('tour.step1.content'),
            placement: 'bottom',
            reflex: true,
            orphan: true,
            onShown: function() {
                tourHelper.highlightFixedElement('#tour-element-1');
            } 
        }
    ];
    
    var clientSteps = [
        {
            element: '#tour-element-2',
            title: $translate.instant('tour.step2.title'),
            content: $translate.instant('tour.step2.content'),
            placement: 'right',
            path: '/clients',
            reflex: true,
            orphan: true,
            onShown: function() {
                tourHelper.highlightFixedElement('#tour-element-2');
            }
        },
        {
            element: '#tour-element-3',
            title: $translate.instant('tour.step3.title'),
            content: $translate.instant('tour.step3.content'),
            placement: 'right',
            path: '/clients/create',
            onShown: function() {
                tourHelper.showNextOnInput('#tour-element-3');
            }
        },
        {
            element: '#tour-element-4',
            title: $translate.instant('tour.step4.title'),
            content: $translate.instant('tour.step4.content'),
            placement: 'top',
            path: '/clients/create',
            reflex: true,
            onShown: function() {
                tourHelper.forceFieldToBeFilled('#tour-element-3', 3);
                tourHelper.removeNextButton();              
            }
        }
    ];
    
    var workplaceSteps = [
        {
            element: '#tour-element-5',
            title: $translate.instant('tour.step5.title'),
            content: $translate.instant('tour.step5.content'),
            placement: 'right',
            prev: -1,
            next: 6,
            path: '/workplaces/create',
            onShown: function() {
                return tourHelper.showNextOnInput('#tour-element-5');
            }
        },
        {
            element: '#tour-element-6',
            title: $translate.instant('tour.step6.title'),
            content: $translate.instant('tour.step6.content'),
            placement: 'top',
            path: '/workplaces/create',
            reflex: true,
            onShown: function(tour) {
                tourHelper.forceFieldToBeFilled('#tour-element-5', 5);
                tourHelper.removeNextButton();                 
            }
        }
    ];
    
    var equipmentTreeSteps = [
        {
            element: '#tour-element-9',
            title: $translate.instant('tour.step9.title'),
            content: $translate.instant('tour.step9.content'),
            placement: 'top',
            path: '/equipment/create',
            reflex: true,
            onShown: function(tour) {
                tourHelper.removeNextButton();
            }
        },
        {
            element: '#tour-element-10',
            title: $translate.instant('tour.step10.title'),
            content: $translate.instant('tour.step10.content'),
            placement: 'top',
            prev: -1,
            next: 11,
            orphan: true,
            path: '/equipment/create',
            onShown: function(tour) {
                tourHelper.goBackIfVisible(tour, '#tour-element-9', 9);
            }
        },
        {
            element: '#tour-element-11',
            title: $translate.instant('tour.step11.title'),
            content: $translate.instant('tour.step11.content'),
            placement: 'top',
            orphan: true,
            path: '/equipment/create',
            onShown: function(tour) {
                tourHelper.goBackIfVisible(tour, '#tour-element-9', 9);
            }
        },
        {
            element: '#tour-element-12',
            title: $translate.instant('tour.step12.title'),
            content: $translate.instant('tour.step12.content'),
            placement: 'top',
            orphan: true,
            reflex: true,
            path: '/equipment/create',
            onShown: function(tour) {
                tourHelper.goBackIfVisible(tour, '#tour-element-9', 9);
                tourHelper.removeNextButton(); 
            }
        },
        {
            element: '#tour-element-13',
            title: $translate.instant('tour.step13.title'),
            content: $translate.instant('tour.step13.content'),
            placement: 'left',
            orphan: true,
            reflex: true,
            path: '/equipment/create',
            onShown: function(tour) {
                tourHelper.highlightFixedElement('#tour-element-13');
                tourHelper.goBackIfVisible(tour, '#tour-element-9', 9);
                tourHelper.removeNextButton(); 
            }
        },
        {
            element: '#tour-element-14',
            title: $translate.instant('tour.step14.title'),
            content: $translate.instant('tour.step14.content'),
            placement: 'top',
            path: '/equipment/create',
            reflex: true,
            redirect: false,
            onShown: function(tour) {
                tourHelper.goBackIfVisible(tour, '#tour-element-9', 9);
                tourHelper.removeNextButton();
            }                
        },
        {
            element: '#tour-element-15',
            title: $translate.instant('tour.step15.title'),
            content: $translate.instant('tour.step15.content'),
            placement: 'bottom',
            reflex: true,
            redirect: false,
            prev: -1,
            next: 16,
            onShown: function() {
                tourHelper.highlightFixedElement('#tour-element-15');
            }
        }
    ];
    
    var reportSteps = [
        {
            element: '#tour-element-16',
            title: $translate.instant('tour.step16.title'),
            content: $translate.instant('tour.step16.content'),
            placement: 'top',
            reflex: true,
            path: '/reports',
            onShown: function() {
                tourHelper.removeNextButton(); 
            }   
        },
        {
            element: '#tour-element-17',
            title: $translate.instant('tour.step17.title'),
            content: $translate.instant('tour.step17.content'),
            placement: 'left',
            orphan: true,
            path: '/reports',
            onShown: function(tour) {
                tourHelper.goBackIfHidden(tour, '#addReport', 16);
                tourHelper.showNextOnInput('#tour-element-17');
                tourHelper.highlightFixedElement('#tour-element-17');
                tourHelper.highlightFixedElement('ul#typeahead-*');
            }
        },
        {
            element: '#tour-element-18',
            title: $translate.instant('tour.step18.title'),
            content: $translate.instant('tour.step18.content'),
            placement: 'left',
            orphan: true,
            path: '/reports',
            onShown: function(tour) {
                tourHelper.goBackIfHidden(tour, '#addReport', 16);
                tourHelper.showNextOnInput('#tour-element-18');
                tourHelper.highlightFixedElement('#tour-element-18');
            }
        },
        {
            element: '#tour-element-19',
            title: $translate.instant('tour.step19.title'),
            content: $translate.instant('tour.step19.content'),
            placement: 'top',
            reflex: true,
            orphan: true,
            path: '/reports',
            onShown: function(tour) {
                tourHelper.goBackIfHidden(tour, '#addReport', 16);
                tourHelper.removeNextButton(); 
                tourHelper.highlightFixedElement('#tour-element-19');
            }  
        },
        {
            element: '#tour-element-20',
            title: $translate.instant('tour.step20.title'),
            content: $translate.instant('tour.step20.content'),
            placement: 'right',
            reflex: true,
            orphan: true,
            prev: -1,
            path: '/reports/1/edit',
            onShown: function() {
                tourHelper.removeNextButton(); 
                tourHelper.highlightFixedElement('#tour-element-20');
            }  
        },
        {
            element: 'textarea',
            title: $translate.instant('tour.step21.title'),
            content: $translate.instant('tour.step21.content'),
            placement: 'top',
            orphan: true,
            path: function() {
                return '/reports/1/edit#/' + $('#tour-element-20').attr('uuid');
            }, 
            onShown: function() {
                return tourHelper.showNextOnInput('textarea');
            }
        },
        {
            element: '#tour-element-22',
            title: $translate.instant('tour.step22.title'),
            content: $translate.instant('tour.step22.content'),
            placement: 'top',
            reflex: true,
            orphan: true,
            path: function() {
                return '/reports/1/edit#/' + $('#tour-element-20').attr('uuid');
            }, 
            onShown: function(tour) {
                tourHelper.removeNextButton(); 
            }  
        },
        {
            element: '#tour-element-23',
            title: $translate.instant('tour.step23.title'),
            content: $translate.instant('tour.step23.content'),
            placement: 'left',
            path: function() {
                return '/reports/1/edit#/' + $('#tour-element-20').attr('uuid');
            }
        },
        {
            element: '#tour-element-24',
            title: $translate.instant('tour.step24.title'),
            content: $translate.instant('tour.step24.content'),
            placement: 'bottom',
            reflex: true,
            path: function() {
                return '/reports/1/edit#/' + $('#tour-element-20').attr('uuid');
            }, 
            onShown: function(tour) {
                tourHelper.removeNextButton();
            }
        },
        {
            element: '#tour-element-25',
            title: $translate.instant('tour.step25.title'),
            content: $translate.instant('tour.step25.content'),
            placement: 'top',
            path: '/reports'
        }
    ];
    
    $scope.startTour = function(forceRestart) {
        
        // tour steps configuration
        forceRestart = forceRestart || false;
        var enableClientSteps = Entrust.can('create_client');
        var enableWorkplaceSteps = Entrust.can(['create_workplace', 'edit_workplace'], true);
        var enableEquipmentTreeSteps = Entrust.can(['create_equipment_tree', 'edit_equipment_tree'], true);
        var enableReportSteps = Entrust.can(['create_report', 'edit_report'], true);
        var enableWelcomeSteps = enableClientSteps && enableWorkplaceSteps && enableEquipmentTreeSteps && enableReportSteps;
        
        var steps = [];
        
        steps = enableWelcomeSteps       ? steps.concat(_.cloneDeep(welcomeSteps))       : steps;
        steps = enableClientSteps        ? steps.concat(_.cloneDeep(clientSteps))        : steps;
        steps = enableWorkplaceSteps     ? steps.concat(_.cloneDeep(workplaceSteps))     : steps;
        steps = enableEquipmentTreeSteps ? steps.concat(_.cloneDeep(equipmentTreeSteps)) : steps;
        steps = enableReportSteps        ? steps.concat(_.cloneDeep(reportSteps))        : steps;
        
        // add progress bar and steps to the tour
        steps.forEach(function (step, index) {
            step.title += '<span class="pull-right"><i class="bi_web-share-b"></i> ' + (index + 1) + '/' + steps.length + '</span>';
            var percent = parseInt( ((index + 1) / steps.length) * 100, 10);
            step.content = '<div class="pbar_wrapper"><hr class="pbar" style="width:' + percent + '%;"></div>' + step.content;
        });
    
        var regex = /https?:\/\/[a-zA-Z-.:\d]+(.*)\//i;
        var basePath = $('base').attr('href').match(regex)[1];
        var debug = false;
        
        var tour = new Tour({
            debug: debug,
            storage: UserSetting,
            backdrop: true,
            basePath: basePath,
            keyboard: false,
            steps: steps,
            delay: false,
            animation: true,
            template: 
                "<div class='popover tour'> \
                    <button class='btn-close' data-role='end'><i class='bi_interface-box-cross'></i></button> \
                    <div class='arrow'></div> \
                    <h3 class='popover-title'></h3> \
                    <div class='popover-content'></div> \
                    <div class='popover-navigation'> \
                        <button class='btn btn-prev' data-role='prev'><i class='bi_interface-arrow-left'></i> " + $translate.instant('tour.previous') + "</button> \
                        <button class='btn btn-next' data-role='next'>" + $translate.instant('tour.next') + " <i class='bi_interface-arrow-right'></i></button> \
                    </div> \
                </div>"
        });
                
        UserSetting.initialize().then(function() {
            tour.init();
            forceRestart ? tour.restart() : tour.start(debug);
        });
    };
    
    /**
     * Init Entrust permissions
     */
    
    Entrust.init();//.then(function() { return $scope.startTour() });

});