angular.module('contactService', []).factory('Contact', function($http) {

    return {
        get: function(clientId) {
            return $http({
                method: 'GET',
                url: 'contacts/' + clientId,
                offline: true
            });
        },
        create: function(data) {
            return $http({
                method: 'POST',
                url: 'contacts',
                offline: true,
                data: data
            });
        },
       	update: function(id, data) {
            return $http({
                method: 'POST',
                url: 'contacts/' + id,
                offline: true,
                data: _.assign({ _method: 'PATCH' }, data)
            });
        },
        destroy: function(clientId) {
            return $http({
                method: 'POST',
                url: 'contacts/' + clientId,
                offline: true,
                data: {_method: 'DELETE'},
            });
        }
    };
});
