angular.module('ticketsCreateController', []).controller('ticketsCreateController', function($scope, $window, Ticket, Route, Entrust, Workplace, Loading, Client) {

	/**
	 * Initial data
	 */
	$scope.continue = true;
	$scope.selectedItem = null;

	var initial = {
		priority: 'highest',
		documents: []
	};

	var currentId = Route.getAction()[1];

	/**
	 * Utils
	 */
	$scope.initTicket = function () {
		$scope.ticket = angular.copy(initial);
		if ($scope.createTicketForm) {
			$scope.createTicketForm.$setUntouched();
		}
	};
	$scope.initTicket();
	$scope.filterTrashed = function(collection) {
		return _.filter(collection, {isTrashed: false});
	};

	$scope.options = {
		accept: function(sourceNodeScope, destNodesScope, destIndex) {
			return true;
		}
	};

	$scope.selectItem = function(item) {
		if (item._id === $scope.selectedItem) {
			$scope.unSelectItem();
		} else {
			$scope.ticket.equipment_id = item._id;
			$scope.selectedItem = item._id;
		}
	};

	$scope.isItemSelected = function(item) {
		return item._id === $scope.selectedItem;
	};

	$scope.unSelectItem = function() {
		$scope.ticket.equipment_id = null;
		$scope.selectedItem = null;
	};

	$scope.getEquipmentTree = function (id) {
		Loading.register('workplace', Workplace.get(id), function (data) {
			$scope.equipment_tree = data.equipment_tree;
		});
	};

	/**
	 * Init ticket when "create ticket" button is fired. Complete client field if the connected user has an associated client
	 */
	$scope.$on('initTicket', function (e) {
		var clientId = Entrust.isClient()
		if (clientId != null) {
			angular.forEach($scope.clients, function(client, key) {
				if (client.id == clientId) {
					$scope.selectedClient = client;
				}
			});
		}
	});

    /**
     * Document listeners
     */
    $scope.$on('documentAdded', function (e, document) {
        $scope.ticket.documents.push(document);
        $('#addDocument' + e.targetScope.$id).modal('hide');
		setTimeout(function(){ $('body').addClass('modal-open') }, 1000);
    });
    
    $scope.$on('documentDestroyed', function (e, document) {
        _.remove($scope.ticket.documents, function (doc) { return doc.id === document.id; });
    });
    
	/**
	 * Model actions
	 */
    
    $scope.create = function($event,form) {
		$scope.$parent.hideSubmitButton($event);
        $scope.loadingGlobal = true;
		if ($scope.$parent.report) {
			_.assign($scope.ticket, {
				report_id: currentId,
				workplace_id: $scope.$parent.report.workplace_id,
				client_id: $scope.$parent.report.client_id,
				equipment_id: $scope.$parent.item._id,
			});
		} else {
			_.assign($scope.ticket, {
				workplace_id: form.workplace.$modelValue.id,
				client_id: form.client.$modelValue.id
			});
		}

		
        Ticket.create($scope.ticket)
            .success(function(ticket) { 	
				if ($scope.$parent.report) {
					$('#addTicket').modal('hide');
					$scope.initTicket();
					$('.alert.alert-success').fadeIn().delay(2000).fadeOut();
				} else {
					var redirectPath = './tickets/' + ticket._id;
					if (Entrust.hasRole('Client') === false) {
						redirectPath += '?notify';
					}
					$window.location = redirectPath;
				}
            })
            .error(function(data) {
                console.log(data); 
                $('.alert.alert-error:not(.license)').fadeIn().delay(2000).fadeOut();
            })
			.finally(function() { $scope.loadingGlobal = false; });
    };
});