function setNanoScroller() {
    $('.nano').nanoScroller({ alwaysVisible: false, iOSNativeScrolling: false });
}

function responsive() {
    
    /* reset resizable of jquery */
    $('.left-nav').css('width', ''); 
    $('.header-logo').css('width', '');
    $('.dashboard-page').css('margin-left', '');

    if ($(window).width() < 1240) {
        $('.left-nav, .header-logo').addClass('close');
        $('.dashboard-page').addClass('full');
    } else {
        $('.left-nav, .header-logo').removeClass('close');
        $('.dashboard-page').removeClass('full');
    }
    setNanoScroller();
}

function toggleLeftNav() {
    /* reset resizable of jquery */
    $('.left-nav').css('width', ''); 
    $('.header-logo').css('width', '');
    $('.dashboard-page').css('margin-left', '');

    $('.left-nav, .header-logo').toggleClass('close');
    $('.dashboard-page').toggleClass('full');

    setNanoScroller();
}

$(function() {

    $('.toggle-click').click(function() {
        if ($(window).width() < 900) {
            toggleLeftNav();
        }
    });

    $("[data-toggle='tooltip']").tooltip();
    $(".left-nav").resizable({ 
        ghost: true,
        handles: "e",
        minWidth: 250,
        stop: function(event, ui) {
            $(this).css('height', ''); // reset height changes
            $('.header-logo').css('width', ui.size.width);
            $('.dashboard-page').css('margin-left', ui.originalSize.width + (ui.size.width - ui.originalSize.width));
       }
    });

    $('#dropdownMenu1').on('shown.bs.dropdown', setNanoScroller);
    $('#choiceForm').on('shown.bs.modal', setNanoScroller);

    $('.ion-navicon-round').click(function() {
        toggleLeftNav();
    });

    $('.nav-menu-responsive').click(function() {
        $('.nav-menu-links').toggleClass('responsive');
        $('.header').toggleClass('responsive');
        setNanoScroller();
    });
    
    responsive();
    _.delay(setNanoScroller, 150);

    $(window).on('resize', function (event) {
        if (!$(event.target).hasClass('ui-resizable'))
            _.debounce(responsive, 150);
    });
});

var app = angular.module('app', [
    'reportsIndexController',
    'reportsShowController',
    'reportsEditController',
    'remarksIndexController',
    'remarksEditController',
    'remarksCreateController',
    'ticketsIndexController',
    'ticketsCreateController',
    'ticketsEditController',
    'ticketsShowController',
    'formsIndexController',
    'formsCreateController',
    'reportsCreateController',
    'clientsCreateController',
    'clientsEditController',
    'clientsIndexController',
    'workplacesCreateController',
    'workplacesEditController',
    'contactsCreateController',
    'contactsEditController',
    'filterService',
    'mainController',
    'settingsController',
    'rolesController',
    'usersController',
    'equipmentController',
    'maintenanceTypesController',
    'passwordController',
    'homeController',
    'timelineController',
    'uploadController',
    'trelloController',
    'signatureController',
    'documentsController',
    'roleService',
    'clientService',
    'contactService',
    'userService',
    'equipmentService',
    'equipmentTypeService',
    'documentService',
    'maintenanceTypeService',
    'formService',
    'operationService',
    'remarkService',
    'ticketService',
    'reportService',
    'workplaceService',
    'alertService',
    'dirtyModelService',
    'draftService',
    'routeService',
    'userSettingService',
    'signatureService',
    'loadingService',
    'entrustService',
    'commentService',
    'angularMoment',
    'oitozero.ngSweetAlert',
    'pascalprecht.translate',
    'ngFileUpload',
    'ui.tree',
    'builder', 
    'builder.components',
    'validator.rules',
    'ui.tree-filter',
    'ngAnimate',
    'ui.bootstrap',
    'ngMap',
    'uuid4',
    'offline',
    'angular-cache',
    'chart.js',
    'signature',
    'angularUtils.directives.dirPagination',
    'moment-picker',
    'ngjsColorPicker'
]);

app.filter('split', function() {
    return function(input, splitChar, splitIndex) {
        // do some bounds checking here to ensure it has that index
        return input.split(splitChar)[splitIndex];
    }
});

app.filter('withTrashed', function() {
    return function(input, bool) {
        var out = [];
        for (var i = 0; i < input.length; i++) {
            if (input[i].isTrashed === true) {
                if (bool) {
                    out.push(input[i]);
                }
            } else {
                out.push(input[i]);
            }
        }
        return out;
    };
});

app.filter('startBy', function(){
    return function(input, text, cible){
        return input.filter(function(item){
            var lowerStr = (item[cible] + "").toLowerCase();
            return lowerStr.indexOf(text.toLowerCase()) === 0;
        });
    };
});

app.filter('attachedToWorkplaceOrClient', function() {
    return function(input, workplaceId) {
        var out = [];
        for (var i = 0; i < input.length; i++) {
            if(input[i].workplace_id == workplaceId || !input[i].workplace_id)
                out.push(input[i]);
        }
        return out;
    };
});

app.filter('nl2br', ['$sce', function($sce){
    return function(msg,is_xhtml) {
        var is_xhtml = is_xhtml || true;
        var breakTag = (is_xhtml) ? '<br />' : '<br>';
        var msg = (msg + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1'+ breakTag +'$2');
        return $sce.trustAsHtml(msg);
    }
}]);

app.filter('unique', function() {
   return function(collection, keyname) {
      var output = [], 
          keys = [];

      angular.forEach(collection, function(item) {
          var key = item[keyname];
          if(keys.indexOf(key) === -1) {
              keys.push(key);
              output.push(item);
          }
      });

      return output;
   };
});

app.filter('cut', function () {
    return function (value, wordwise, max, tail) {
        if (!value) return '';

        max = parseInt(max, 10);
        if (!max) return value;
        if (value.length <= max) return value;

        value = value.substr(0, max);
        if (wordwise) {
            var lastspace = value.lastIndexOf(' ');
            if (lastspace !== -1) {
                //Also remove . and , so its gives a cleaner result.
                if (value.charAt(lastspace-1) === '.' || value.charAt(lastspace-1) === ',') {
                    lastspace = lastspace - 1;
                }
                value = value.substr(0, lastspace);
            }
        }

        return value + (tail || ' …');
    };
});

app.filter('range', function() {
    return function(input, total) {
        total = parseInt(total);

        for (var i=0; i<total; i++) {
            input.push(i);
        }

        return input;
    };
});

app.filter('bytes', function($translate) {
	return function(bytes, precision) {
        if (bytes == 0) return '0 ' + $translate.instant('documentUnits.bytes');
		if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return $translate.instant('unknownSize');
		if (typeof precision === 'undefined') precision = 1;
		var units = [
            $translate.instant('documentUnits.bytes'), 
            $translate.instant('documentUnits.kB'), 
            $translate.instant('documentUnits.MB'), 
            $translate.instant('documentUnits.GB'),
            $translate.instant('documentUnits.TB'),
            $translate.instant('documentUnits.PB')
        ],
			number = Math.floor(Math.log(bytes) / Math.log(1024));
		return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +  ' ' + units[number];
	}
});

app.config(function ($translateProvider, $httpProvider, offlineProvider, ChartJsProvider, momentPickerProvider) {

    $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    $httpProvider.interceptors.push(function($q, $window) {
        return {
            'responseError': function(rejection) {
                
                if (rejection.status == 401) {
                    console.warn('request rejected 401');
                    $window.location = './sign-in';
                }

                return $q.reject(rejection);
            }
        };
    });

    offlineProvider.debug(true);
    
    ChartJsProvider.setOptions({
        responsive: true,
        maintainAspectRatio: true
    });

    momentPickerProvider.options({
        /* Picker properties */
        locale:        $('html').attr('lang'),
        format:        'dddd LL',
        minView:       'month',
        maxView:       'month',
        startView:     'month',
        autoclose:     true,
        today:         true,
        keyboard:      false,
        
        /* Extra: Views properties */
        leftArrow:     '&larr;',
        rightArrow:    '&rarr;',
        yearsFormat:   'YYYY',
        monthsFormat:  'MMM',
        daysFormat:    'D',
        hoursFormat:   'HH:[00]',
        minutesFormat: moment.localeData().longDateFormat('LT').replace(/[aA]/, ''),
        secondsFormat: 'ss',
        minutesStep:   5,
        secondsStep:   1
    });

    $translateProvider.useMessageFormatInterpolation();
    $translateProvider.useSanitizeValueStrategy('escaped');
    $translateProvider.preferredLanguage($('html').attr('lang'));

    $translateProvider.translations('en', {
        client: {
            stats: {
                reports: '{VAL, plural, =0{No report} one{One report} other{# reports}}',
                remarks: '{VAL, plural, =0{No remark} one{One remark} other{# remarks}}',
                tickets: '{VAL, plural, =0{No ticket} one{One ticket} other{# tickets}}',
                states: {
                    opened: '{VAL, plural, =0{No opened} one{One opened} other{# opened}}',
                    finished: '{VAL, plural, =0{No finished} one{One finished} other{# finished}}',
                    forwarded: '{VAL, plural, =0{No forwarded} one{One forwarded} other{# forwarded}}'
                }
            }
        },
        report: {
            state: {
                opened: 'Opened',
                finished: 'Finished',
                forwarded: 'Forwarded'
            },
            stats: {
                operation: '{VAL, plural, =0{No operation} one{One operation} other{# operations}}',
                operator: '{VAL, plural, =0{No operator} one{One operator} other{# operators}}'
            }
        },
        tickets: {
            stats: {
                tickets: '{VAL, plural, =0{No tickets} one{One ticket} other{# tickets}}',
                opened: '{VAL, plural, =0{No opens} one{One open} other{# opens}}',
                'in progress': '{VAL, plural, =0{No in progress} one{One in progress} other{# in progress}}',
                resolved: '{VAL, plural, =0{No solved} one{One solved} other{# solved}}',
                abandoned: '{VAL, plural, =0{No abandoned} one{One abandoned} other{# abandoned}}',
            },
            state: {
                opened: 'Open',
                'in progress': 'In progress',
                resolved: 'Solved',
                abandoned: 'Abandoned'
            },
            priority: {
                highest: 'Highest',
                high: 'High',
                medium: 'Medium',
                low: 'Low',
                lowest: 'Lowest'
            }
        },
        remarks: {
            stats: {
                opened: '{VAL, plural, =0{No opened} one{One opened} other{# opened}}',
                'in progress': '{VAL, plural, =0{No in progress} one{One in progress} other{# in progress}}',
                closed: '{VAL, plural, =0{No closed} one{One closed} other{# closed}}',
                archived: '{VAL, plural, =0{No archived} one{One archived} other{# archived}}',
                'won\'t fix': '{VAL, plural, =0{No won\'t fix} one{One won\'t fix} other{# won\'t fix}}'
            },
            state: {
                opened: 'Opened',
                'in progress': 'In progress',
                closed: 'Closed',
                archived: 'Archived',
                'won\'t fix': 'Won\'t fix'
            },
            priority: {
                highest: 'Highest',
                high: 'High',
                medium: 'Medium',
                low: 'Low',
                lowest: 'Lowest'
            }
        },
        sweetalert: {
            title: "Are you sure?",
            text: "You will not be able to recover this item!",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
            success: {
                title: "Deleted!",
                text: "This item has been deleted."
            },
            error: {
                title: "Cancelled",
                text: "This item hasn't been deleted."
            },
            reopen: {
                text: "Signatures associated with this report will be deleted",
                confirmButtonText: "Yes, reopened it !",
                success: {
                    title: "Reopened !",
                    text: "This item has been reopened."
                },
            }
        },
        datepicker: {
            monthsFull: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ],
            monthsShort: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],
            weekdaysFull: [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ],
            weekdaysShort: [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ],
            today: 'Today',
            clear: 'Clear',
            close: 'Close',
            firstDay: '0',
            format: 'd mmmm, yyyy'
        },
        onbeforeunload: "Warning ! Some unsaved changes have been made. Are you sure you want to leave this page?",
        newDocument: "New document",
        uploadDocument: "Uploading...",
        failDocument: "Error during upload: quota exceedeed",
        rootNode: "Root node",
        newTree: "New tree from scratch",
        newForm: "New formulary",
        unknownSize: "Unknown size",
        documentUnits: {
            bytes: 'bytes',
            kB: 'kB', 
            MB: 'MB', 
            GB: 'GB',
            TB: 'TB',
            PB: 'PB'
        },
        tour: {
            previous: "Prev",
            next: "Next",
            step0: {
                title: "Welcome to Report Up!",
                content: "Let me guide you through the application for the first use."
            },
            step1: {
                title: "Welcome to Report Up!",
                content: "First, let's create a client. The client list is available through this link."
            },
            step2: {
                title: "Client creation",
                content: "Here we are! To create a new client, you can click on this button.",
            },
            step3: {
                title: "Client creation",
                content: "Here you've got all the informations about the client. To go faster, let's just fill the designation.",
                extra: "My first client"
            },
            step4: {
                title: "Client creation",
                content: "By clicking on this button, we are going to add our new client."
            },
            step5: {
                title: "Workplace creation",
                content: "Second step of the process, we are going to associate a workplace to this client. One more time, you can fill just only the designation.",
                extra: "My first workplace"
            },
            step6: {
                title: "Workplace creation",
                content: "By clicking on this button, we are going to associate a workplace to our client."
            },
            step9: {
                title: "Equipment tree creation",
                content: "Well, here we go ! This final step will allow us to make a tree of your workplace's equipment. To do this, we will create an empty tree by clicking on this button."
            },
            step10: {
                title: "Equipment tree creation",
                content: "The first panel lets you manage your overall tree, ie its designation and its equipment."
            },
            step11: {
                title: "Equipment tree creation",
                content: "The second panel allows to fill the informations of an equipment."
            },
            step12: {
                title: "Equipment tree creation",
                content: "Let's start to associate a form with this equipment. To do this, click on this button to select a form."
            },
            step13: {
                title: "Equipment tree creation",
                content: "Choose the Standard Inspection Form by clicking on this revision."
            },
            step14: {
                title: "Equipment tree creation",
                content: "Save your changes by clicking on this button."
            },
            step15: {
                title: "Equipment tree creation",
                content: "Perfect, your tree is over! Let me now introduce you to the reports. The list of reports is available through this link."
            },
            step16: {
                title: "Report creation",
                content: "First create a new report by clicking on this button."
            },
            step17: {
                title: "Report creation",
                content: "Choose the client we've created. For this type the name of your client."
            },
            step18: {
                title: "Report creation",
                content: "We also select our workplace in this dropdown."
            },
            step19: {
                title: "Report creation",
                content: "Our tree has been found! Now we can create this report by clicking on this button."
            },
            step20: {
                title: "Report creation",
                content: "Our report has been successfully created! However, for the moment, it's empty. We will remedy this by selecting our first equipment."
            },
            step21: {
                title: "Report creation",
                content: "Once we've selected our equipment, we find back the form that we have associated with it. By completing this field, we will create a new operation."
            },
            step22: {
                title: "Report creation",
                content: "Click on this button to validate the creation of your new operation."
            },
            step23: {
                title: "Report creation",
                content: "You can also create a remark with this button."
            },
            step24: {
                title: "Report creation",
                content: "Finally, let's finish our report by clicking on this button."
            },
            step25: {
                title: "Achievement unlocked!",
                content: "Your report is now available for processing! Thank you for following this tutorial, we hope to continue to give you satisfaction in the future. The Report Up team."
            }
        },
        formbuilder: {
            group: {
                special: 'Special components'
            },
            file: {
                label: 'Document',
                description: 'Upload a document',
                validationOptions: {
                    all: 'all files',
                    image: 'only images',
                    video: 'only videos',
                    pdf: 'only PDF',
                    word: 'only Word documents',
                    excel: 'only Excel documents',
                    archive: 'only archives'
                }
            },
            datepicker: {
                label: 'Calendar',
                description: 'Pick a date',
                placeholder: 'Please pick a date'         
            },
            text: {
                label: 'Text block',
                description: 'My content'
            },
            severity: {
                label: 'Severity',
                description: 'Severity colour code',
                critical: 'Critical',
                significant: 'To correct',
                minor: 'RAS',
                not_defined: 'Not defined'
            },
            chart: {
                label: 'Chart',
                description: 'Y as a function of X',
                x_axis: 'Axis X',
                y_axis: 'Axis Y'
            },
            array: {
                label: 'Array',
                description: 'My array',
            },
            lineBreak: {
                label: 'Line Break',
                description: 'Only visible on PDF'
            },
            pageBreak: {
                label: 'Page Break',
                description: 'Only visible on PDF'
            }
        }
    });

    $translateProvider.translations('fr', {
        client: {
            stats: {
                reports: '{VAL, plural, =0{Aucun rapport} one{Un rapport} other{# rapports}}',
                remarks: '{VAL, plural, =0{Aucune remarque} one{Une remarque} other{# remarques}}',
                tickets: '{VAL, plural, =0{Aucun ticket} one{Un ticket} other{# tickets}}',
                states: {
                    opened: '{VAL, plural, =0{Aucun ouvert} one{Un ouvert} other{# ouverts}}',
                    finished: '{VAL, plural, =0{Aucun fini} one{Un fini} other{# finis}}',
                    forwarded: '{VAL, plural, =0{Aucun transmis} one{Un transmis} other{# transmis}}'
                }
            }
        },
        report: {
            state: {
                opened: 'Ouvert',
                finished: 'Fini',
                forwarded: 'Transmis'
            },
            stats: {
                operation: '{VAL, plural, =0{Aucune opération} one{Une opération} other{# opérations}}',
                operator: '{VAL, plural, =0{Aucun intervenant} one{Un intervenant} other{# intervenants}}'
            }
        },
        tickets: {
            stats: {
                tickets: '{VAL, plural, =0{Aucun ticket} one{Un ticket} other{# tickets}}',
                opened: '{VAL, plural, =0{Aucun ouvert} one{Un ouvert} other{# ouverts}}',
                'in progress': '{VAL, plural, =0{Aucun en cours} one{Un en cours} other{# en cours}}',
                resolved: '{VAL, plural, =0{Aucun résolu} one{Un résolu} other{# résolus}}',
                abandoned: '{VAL, plural, =0{Aucun abandonné} one{Un abandonné} other{# abandonnés}}',
            },
            state: {
                opened: 'Ouvert',
                'in progress': 'En cours',
                resolved: 'Résolu',
                abandoned: 'Abandonné'
            },
            priority: {
                highest: 'Très élevée',
                high: 'Elevé',
                medium: 'Moyenne',
                low: 'Faible',
                lowest: 'Très faible'
            }
        },
        remarks: {
            stats: {
                opened: '{VAL, plural, =0{Aucune ouverte} one{Une ouverte} other{# ouvertes}}',
                'in progress': '{VAL, plural, =0{Aucune en cours} one{Une en cours} other{# en cours}}',
                closed: '{VAL, plural, =0{Aucune fermée} one{Une fermée} other{# fermées}}',
                archived: '{VAL, plural, =0{Aucune archivée} one{Une archivée} other{# archivées}}',
                'won\'t fix': '{VAL, plural, =0{Aucune abandonnée} one{Une abandonnée} other{# abandonnées}}'
            },
            state: {
                opened: 'Ouvert',
                'in progress': 'En cours',
                closed: 'Fermé',
                archived: 'Archivé',
                'won\'t fix': 'Abandonné'
            },
            priority: {
                highest: 'Très élevée',
                high: 'Elevé',
                medium: 'Moyenne',
                low: 'Faible',
                lowest: 'Très faible'
            }
        },
        sweetalert: {
            title: "Etes-vous sûr ?",
            text: "Vous ne pourrez plus récupérer cet élément !",
            confirmButtonText: "Oui, supprimez-le !",
            cancelButtonText: "Annuler",
            success: {
                title: "Supprimé !",
                text: "Cet élément a été supprimé."
            },
            error: {
                title: "Annulé",
                text: "Cet élément n'a pas été supprimé."
            },
            reopen: {
                text: "Les signatures associées à ce rapport seront supprimées",
                confirmButtonText: "Oui, réouvrez-le !",
                success: {
                    title: "Réouvert !",
                    text: "Cet élément a été réouvert."
                },
            }
        },
        datepicker: {
            monthsFull: [ 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre' ],
            monthsShort: [ 'Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jui', 'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec' ],
            weekdaysFull: [ 'Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi' ],
            weekdaysShort: [ 'Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam' ],
            today: "Aujourd'hui",
            clear: 'Effacer',
            close: 'Fermer',
            firstDay: '1',
            format: 'dddd dd mmmm yyyy'
        },
        onbeforeunload: "Attention ! Des changements non sauvegardés ont été effectués. Etes-vous sûr de vouloir quitter cette page ?",
        newDocument: "Nouveau document",
        uploadDocument: "En cours de téléchargement...",
        failDocument: "Erreur lors de l'envoi : quota dépassé",
        rootNode: "Noeud racine",
        newTree: "Nouvelle arborescence vide",
        newForm: "Nouveau formulaire",
        unknownSize: "Taille inconnue",
        documentUnits: {
            bytes: 'octets',
            kB: 'Ko', 
            MB: 'Mo', 
            GB: 'Go',
            TB: 'To',
            PB: 'Po'
        },
        tour: {
            previous: "Préc.",
            next: "Suiv.",
            step0: {
                title: "Bienvenue sur Report Up !",
                content: "Laissez-moi vous guider à travers l'application pour cette première prise en main."
            },
            step1: {
                title: "Bienvenue sur Report Up !",
                content: "Premièrement, commençons par créer un client. La liste des clients est accessible via ce lien."
            },
            step2: {
                title: "Création d'un client",
                content: "Nous y sommes ! Pour créer un nouveau client, vous pouvez cliquer sur ce bouton.",
            },
            step3: {
                title: "Création d'un client",
                content: "Ici vous avez toutes les informations du client. Pour aller plus vite, vous pouvez ne renseigner que la désignation.",
                extra: "Mon premier client"
            },
            step4: {
                title: "Création d'un client",
                content: "En cliquant sur ce bouton, nous allons ajouter notre nouveau client."
            },
            step5: {
                title: "Création d'un site",
                content: "Deuxième étape du processus, nous allons associer un site à ce client. Une fois de plus, vous pouvez ne renseigner que la désignation.",
                extra: "Mon premier site"
            },
            step6: {
                title: "Création d'un site",
                content: "En cliquant sur ce bouton, nous allons associer un site à notre client."
            },
            step9: {
                title: "Création d'une arborescence",
                content: "Bien, nous y voilà ! Cette ultime étape nous permettra de mettre en place une arborescence des équipements de votre site. Pour cela, nous allons créer une arborescence vide en cliquant sur ce bouton."
            },
            step10: {
                title: "Création d'une arborescence",
                content: "Ce premier panneau vous permet de gérer votre arborescence globale, c'est à dire sa désignation et ses équipements."
            },
            step11: {
                title: "Création d'une arborescence",
                content: "Ce second panneau permet lui de renseigner les informations d'un équipement."
            },
            step12: {
                title: "Création d'une arborescence",
                content: "Commençons par associer un formulaire à cet équipement. Pour cela, cliquez sur ce bouton afin de choisir un formulaire.",
            },
            step13: {
                title: "Création d'une arborescence",
                content: "Choisissez le Formulaire Standard d'Inspection en cliquant sur cette révision."
            },
            step14: {
                title: "Création d'une arborescence",
                content: "Sauvegardez les changements effectués en cliquant sur ce bouton."
            },
            step15: {
                title: "Création d'une arborescence",
                content: "Parfait, votre arborescence est terminée ! Laissez-moi maintenant vous présenter les rapports. La liste des rapports est accessible via ce lien."
            },
            step16: {
                title: "Création d'un rapport",
                content: "Commençons par créer un nouveau rapport en cliquant sur ce bouton."
            },
            step17: {
                title: "Création d'un rapport",
                content: "Choisissons le client que nous avons créé. Pour cela tapez le nom de votre client."
            },
            step18: {
                title: "Création d'un rapport",
                content: "Sélectionnons également notre site dans cette liste déroulante."
            },
            step19: {
                title: "Création d'un rapport",
                content: "Notre arborescence a été trouvée ! Maintenant nous pouvons créer ce rapport en cliquant sur ce bouton."
            },
            step20: {
                title: "Création d'un rapport",
                content: "Notre rapport a été créé avec succès ! Cependant, pour le moment, il est vide. Remédions à cela en sélectionnant notre premier équipement."
            },
            step21: {
                title: "Création d'un rapport",
                content: "Une fois notre équipement sélectionné, nous retrouvons le formulaire que nous lui avons associé. En remplissant ce champ, nous allons créer une nouvelle opération."
            },
            step22: {
                title: "Création d'un rapport",
                content: "Cliquez sur ce bouton pour valider la création de votre nouvelle opération."
            },
            step23: {
                title: "Création d'un rapport",
                content: "Vous pouvez également créer une remarque grâce à ce bouton."
            },
            step24: {
                title: "Création d'un rapport",
                content: "Finalement, terminons notre rapport en cliquant sur ce bouton."
            },
            step25: {
                title: "Succès débloqué !",
                content: "Votre rapport est dorénavant disponible pour traitement ! Merci d'avoir suivi ce tutoriel, nous esperons continuer à vous donner satisfaction à l'avenir. L'équipe Report Up."
            }
        },
        formbuilder: {
            group: {
                special: 'Composants spéciaux'
            },
            file: {
                label: 'Document',
                description: 'Transférer un document',
                validationOptions: {
                    all: 'tous les fichiers',
                    image: 'seulement les images',
                    video: 'seulement les vidéos',
                    pdf: 'seulement les PDF',
                    word: 'seulement les documents Word',
                    excel: 'seulement les documents Excel',
                    archive: 'seulement les archives'
                }
            },
            datepicker: {
                label: 'Calendrier',
                description: 'Choisir une date',
                placeholder: 'Choisissez une date'         
            },
            text: {
                label: 'Bloc de texte',
                description: 'Mon contenu'
            },
            severity: {
                label: 'Gravité',
                description: 'Code couleur gravité',
                critical: 'Critique',
                significant: 'A corriger',
                minor: 'RAS',
                not_defined: 'Non défini'
            },
            chart: {
                label: 'Graphique',
                description: 'Y en fonction de X',
                x_axis: 'Axe X',
                y_axis: 'Axe Y'
            },
            array: {
                label: 'Tableau',
                description: 'Mon tableau',
            },
            lineBreak: {
                label: 'Saut de ligne',
                description: 'Visible uniquement sur les PDF'
            },
            pageBreak: {
                label: 'Saut de page',
                description: 'Visible uniquement sur les PDF'
            }
        }
    });
});

app.run(function(amMoment, $builder, connectionStatus, offline, $http, $cacheFactory, CacheFactory, $translate, $rootScope, Loading, Filter, Entrust) {
    
    $rootScope.loading = Loading;
    $rootScope.filter = Filter;
    $rootScope.entrust = Entrust;
    
    $http.defaults.cache = $cacheFactory('custom');

    offline.stackCache = CacheFactory.createCache('my-cache', { storageMode: 'localStorage' });
    offline.start($http);

    amMoment.changeLocale($('html').attr('lang'));

    
});

app.directive('activitySelector', function() {
    return {
        restrict: 'E',
        templateUrl: 'ng-templates/activity-selector',
        link: function(scope, element, attrs) {
            if (attrs.editable !== undefined) {
                scope.editable = true;
            }
        }
    };
});

// bind bootstrap-tour to angular
app.provider('Tour', function() {
    this.$get = function() {
        return window.Tour;
    };
});

app.constant('upload_max_filesize', 5); // in MB
app.constant('mime_types_allowed', '.jpeg,.jpg,.gif,.png,.svg,.pdf,.doc,.docx,.xls,.xlsx,.zip,.rar,.tar.gz,.mp4,.mov,.avi,.wmv,.3gp');
app.constant('image_max_width', 800); // in pixels
