angular.module('documentService', []).factory('Document', function($http, Upload) {

    return {

        get: function() {
            return $http({
                method: 'GET',
                url: 'admin/documents',
                offline: true
            });
        },
        create: function(file, filename) {
            return Upload.upload({
                url:  'documents' + (filename != null ? '/' + filename : ''),
                file: file
            });
        },
        destroy: function(url) {
            return $http({
                method: 'POST',
                url: 'documents/' + url,
                offline: true,
                data: {_method: 'DELETE'}
            });
        }
    };
});
