angular.module('documentsController', []).controller('documentsController', function($scope, Document, Alert, Loading) {

	/**
	 * Initial data
	 */
	
    $scope.documents = [];
    $scope.ITEMS_PER_PAGE = 10;

	/**
	 * Utils
	 */
	
	$scope.timestampToDate = function(timestamp) {
		return new Date(timestamp);	
	};

	/**
	 * Get asynchronous data
	 */
	
	Loading.register('documents', Document.get(), function (data) { $scope.documents = data; });
    
	/**
	 * Model actions
	 */

    $scope.destroy = function (document) {
        Alert.confirm(
            function() { return Document.destroy(document.url) }, 
            function() { _.remove($scope.documents, function (doc) { return doc.url === document.url; })}
        );
    };
});
